<!--座位列表组件-->
<template>
  <!--左边座位-->
  <div class="left">
    <header class="text-c mb-2x">
      <!--座位状态-->
      <ul class="flex-around ph-4x">
        <!--productSaleStatus:1可售，2过道，3不可售, 4已售，5 预留-->
        <!--可售-默认-->
        <li>
          <svg
            t="1633941722913"
            class="text-v"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4182"
            width="24"
            height="24"
          >
            <path
              d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
              fill="#999"
              p-id="4183"
            ></path>
            <path
              d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
              fill="#ffffff"
              p-id="4184"
            ></path>
            <path
              d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
              fill="#ffffff"
              p-id="4185"
            ></path>
          </svg>
          <span class="text-v font-l ml-x">可选座位</span>
        </li>

        <!--已售-->
        <li>
          <svg
            t="1633941722913"
            class="text-v"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4182"
            width="24"
            height="24"
          >
            <path
              d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
              fill="#666666"
              p-id="4183"
            ></path>
            <path
              d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
              fill="#f94319"
              p-id="4184"
            ></path>
            <path
              d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
              fill="#f94319"
              p-id="4185"
            ></path>
          </svg>
          <span class="text-v font-l ml-x">已售座位</span>
        </li>
        <!--选中-->
        <li>
          <svg
            t="1633941515669"
            class="text-v"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5987"
            width="24"
            height="24"
          >
            <path
              d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
              fill="#008937"
              p-id="5988"
            ></path>
            <path
              d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
              fill="#00C250"
              p-id="5989"
            ></path>
            <path
              d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
              fill="#1DC673"
              p-id="5990"
            ></path>
            <path
              d="M305.4 497c-1.5-1.6-1.7-4.4-0.4-6.2l18-25.3c1.3-1.8 3.7-2.2 5.4-0.8l102.3 80.9c3.5 2.7 8.9 2.6 12.3-0.3L695.8 328c1.7-1.4 4.3-1.3 5.8 0.2l17.1 17.4c1.5 1.6 1.5 4.1 0 5.7L442.5 631.9c-3.1 3.1-8 3-11-0.2L305.4 497z m0 0"
              fill="#FFFFFF"
              p-id="5991"
            ></path>
          </svg>
          <span class="text-v font-l ml-x">选中座位</span>
        </li>

        <!--禁用、不可售-->
        <li>
          <svg
            t="1633941722913"
            class="text-v"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4182"
            width="24"
            height="24"
          >
            <path
              d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
              fill="#999999"
              p-id="4183"
            ></path>
            <path
              d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
              fill="#c0c4cc"
              p-id="4184"
            ></path>
            <path
              d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
              fill="#c0c4cc"
              p-id="4185"
            ></path>
          </svg>
          <span class="text-v font-l ml-x">禁用座位</span>
        </li>
        <!--预留座位-->
        <li>
          <svg
            t="1633941722913"
            class="text-v"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4182"
            width="24"
            height="24"
          >
            <path
              d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
              fill="#999999"
              p-id="4183"
            ></path>
            <path
              d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
              fill="#44b8fb"
              p-id="4184"
            ></path>
            <path
              d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
              fill="#44b8fb"
              p-id="4185"
            ></path>
          </svg>
          <span class="text-v font-l ml-x">预留座位</span>
        </li>
      </ul>
    </header>
    <!--舞台-->
    <!--选座-->
    <div class="content" :style="{ height, width: stageWidth }">
      <!--舞台-->
      <header class="text-c header" v-show="horizontalNums.length">
        <div class="stage"></div>
        <p class="mb-1x">银幕中央</p>

        <!--头部编号-->
        <ul class="flex-start pr-2x">
          <li class="number all-col" @click="checkAll">全部</li>
          <li
            class="number number-h"
            v-for="(item, index) in horizontalNums"
            :key="'h' + index"
            @click="checkCol(item, index)"
          >
            {{ item || "道" }}
          </li>
        </ul>
      </header>

      <div class="flex-start bottom-row">
        <!--左边灰色编号列-->
        <ul class=" left-col">
          <li
            class="number number-v"
            v-for="(item, index) in verticalNums"
            :key="'v' + index"
            @click="checkRow(item, index)"
          >
            {{ item || "道" }}
          </li>
        </ul>
        <!--座位列表-->
        <ul>
          <li v-for="(item, index) in seatList" :key="index" class="flex-start">
            <section
              class="set-box flex-shrink"
              :class="{ sell: temp.productSaleStatus === 1 }"
              :style="{ backgroundColor: temp.color || '#fff' }"
              v-for="(temp, idx) in item"
              :key="idx"
              :title="temp.productName"
              @click="checkSet(item, temp)"
            >
              <!--productSaleStatus:1可售，2过道，3不可售, 4已售，5 预留-->
              <!--选中-->
              <svg
                t="1633941515669"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="5987"
                width="32"
                height="32"
                v-if="temp.selected"
              >
                <path
                  d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
                  fill="#008937"
                  p-id="5988"
                ></path>
                <path
                  d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
                  fill="#00C250"
                  p-id="5989"
                ></path>
                <path
                  d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
                  fill="#1DC673"
                  p-id="5990"
                ></path>
                <path
                  d="M305.4 497c-1.5-1.6-1.7-4.4-0.4-6.2l18-25.3c1.3-1.8 3.7-2.2 5.4-0.8l102.3 80.9c3.5 2.7 8.9 2.6 12.3-0.3L695.8 328c1.7-1.4 4.3-1.3 5.8 0.2l17.1 17.4c1.5 1.6 1.5 4.1 0 5.7L442.5 631.9c-3.1 3.1-8 3-11-0.2L305.4 497z m0 0"
                  fill="#FFFFFF"
                  p-id="5991"
                ></path>
              </svg>
              <!--禁用、不可说-->
              <svg
                v-else-if="temp.productSaleStatus === 3"
                t="1633941722913"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4182"
                width="32"
                height="32"
              >
                <path
                  d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
                  fill="#999999"
                  p-id="4183"
                ></path>
                <path
                  d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
                  fill="#c0c4cc"
                  p-id="4184"
                ></path>
                <path
                  d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
                  fill="#c0c4cc"
                  p-id="4185"
                ></path>
              </svg>
              <!--已售-->
              <svg
                v-else-if="temp.productSaleStatus === 4"
                t="1633941722913"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4182"
                width="32"
                height="32"
              >
                <path
                  d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
                  fill="#666666"
                  p-id="4183"
                ></path>
                <path
                  d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
                  fill="#f94319"
                  p-id="4184"
                ></path>
                <path
                  d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
                  fill="#f94319"
                  p-id="4185"
                ></path>
              </svg>
              <!--预留-->
              <svg
                v-else-if="temp.productSaleStatus === 5"
                t="1633941722913"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4182"
                width="32"
                height="32"
              >
                <path
                  d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
                  fill="#999999"
                  p-id="4183"
                ></path>
                <path
                  d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
                  fill="#44b8fb"
                  p-id="4184"
                ></path>
                <path
                  d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
                  fill="#44b8fb"
                  p-id="4185"
                ></path>
              </svg>
              <!--可售-默认-->
              <svg
                v-else-if="temp.productSaleStatus === 1"
                t="1633941722913"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4182"
                width="32"
                height="32"
              >
                <path
                  d="M940.9 184.8C926.7 79.1 839.3 0.3 735.9 0H288.1C184.6 0.2 97.2 79.1 83.1 184.8c-48.4 15-82 60.4-83.1 112.5v528.5c0.7 63.6 50.5 115 112.1 115.7h12.6c39.7 51.3 99.7 81.6 163.3 82.5h447.9c63.6-0.9 123.6-31.2 163.3-82.5h12.6c61.6-0.7 111.4-52.1 112.1-115.7V297.3c-1-52.1-34.6-97.5-83-112.5z"
                  fill="#999"
                  p-id="4183"
                ></path>
                <path
                  d="M288.1 32.9H736c84.9-0.3 157.7 62.4 172.8 148.7-60.4 2.2-108.4 53.3-108.7 115.7v528.5H223.9V297.3c-0.3-62.4-48.2-113.5-108.7-115.7 15.1-86.3 88-148.9 172.9-148.7z"
                  fill="#ffffff"
                  p-id="4184"
                ></path>
                <path
                  d="M31.9 825.8V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5c0 45.6-35.8 82.6-80.1 82.6s-80.1-37-80.1-82.6zM735.9 991.1H288.1c-49.8-0.8-97.2-22.3-131.4-59.7 29.8-13.7 52.1-40.3 61-72.7h585.6c8.9 32.3 31 58.9 60.6 72.7-32.4 37.9-79 59.6-128 59.7zM992.1 825.8c0 45.6-35.8 82.6-80.1 82.6-44.2 0-80.1-37-80.1-82.6V297.3c0-45.6 35.8-82.6 80.1-82.6 44.2 0 80.1 37 80.1 82.6v528.5z"
                  fill="#ffffff"
                  p-id="4185"
                ></path>
              </svg>
            </section>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeatList",
  props: {
    // 排编号
    horizontalNums: {
      type: Array,
      default() {
        return [];
      }
    },
    // 列编号
    verticalNums: {
      type: Array,
      default() {
        return [];
      }
    },
    // 座位列表
    seatList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 票档
    levelList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      height: "650px"
    };
  },
  created() {
    let height = window.innerHeight;
    if (height < 800) this.height = 550 + "px";
  },
  computed: {
    // 舞台宽度
    stageWidth() {
      return (this.horizontalNums.length + 1) * 42 + "px";
    }
  },
  methods: {
    // 单个选座
    checkSet(item, temp) {
      if (temp.productSaleStatus === 1) {
        temp.selected = !temp.selected;
        this.calcChecked();
        this.setTheaterTicket(temp, temp.selected);
      }
    },
    // 选择全部
    checkAll() {
      // 查找是否有未选中
      let some = this.seatList.some(item => {
        return item.some(
          temp => temp.productSaleStatus === 1 && !temp.selected
        );
      });
      this.seatList.map(item => {
        item.forEach(temp => {
          if (temp.productSaleStatus === 1) {
            temp.selected = some;
            this.setTheaterTicket(item, some);
          }
        });
      });
      this.calcChecked();
    },
    // 选择列
    checkCol(num, index) {
      if (!num) return;
      // 只要有一个未选中就整列选中，否则整列不选中
      let some = this.seatList.some(item => {
        return !item[index].selected && item[index].productSaleStatus === 1;
      });

      this.seatList.forEach(item => {
        if (item[index].productSaleStatus === 1) {
          item[index].selected = some;
          this.setTheaterTicket(item[index], some);
        }
      });
      this.calcChecked();
    },
    // 选择行
    checkRow(num, index) {
      if (!num) return;
      // 只要有一个未选中就整行选中，否则整行不选中
      const obj = this.seatList[index];
      let some = obj.some(
        item => !item.selected && item.productSaleStatus === 1
      );
      obj.forEach(item => {
        if (item.productSaleStatus === 1) {
          item.selected = some;
          this.setTheaterTicket(item, some);
        }
      });
      this.calcChecked();
    },
    // 给座位设置剧场票id, 用于后面添加购物车用
    setTheaterTicket(item, checked) {
      this.$emit("set-theater-ticket", item, checked);
    },
    // 计算已选中
    calcChecked() {
      const arr = [];
      this.seatList.map(item => {
        item.forEach(temp => {
          if (temp.selected) {
            arr.push(temp);
          }
        });
      });
      // 返回选中的座位
      this.$emit("change", arr);
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../../assets/less/custom";
/*左边*/
.left {
  flex-grow: 1;
  margin-right: 16px;
  /*座位*/
  .content {
    position: relative;
    margin: 0 auto;
    max-width: 936px;
    height: 500px;
    overflow: auto;
    .header {
      position: sticky;
      top: 0;
      left: auto;
      z-index: 1;
      background: #fff;

      > div,
      > p {
        padding-left: 14px;
      }
      /*舞台*/
      .stage {
        width: 70%;
        height: 16px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.23);
        background: @bgColor;
        margin: 0 auto 8px;
      }
    }
    .number {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      line-height: 30px;
      text-align: center;
      border: 1px solid @border;
      border-radius: 4px;
      flex-shrink: 0;
      margin-bottom: 10px;
      margin-right: 10px;
      font-size: 12px;
      background: @bgColor;
      color: @gray;
      cursor: pointer;
      user-select: none;
      &:hover {
        border-color: @primary;
        color: @primary;
      }
    }
    .all-col {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 10;
    }
    .left-col {
      position: sticky;
      left: 0;
      top: auto;
    }
    .header,
    .bottom-row {
      width: max-content;
    }

    /*座位*/
    .set-box {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      line-height: 32px;
      text-align: center;
      flex-shrink: 0;
      margin-bottom: 10px;
      margin-right: 10px;
      cursor: not-allowed;
      &.sell {
        cursor: pointer;
      }
    }
  }
}
</style>
