<template>
  <a-modal
    :visible="visible"
    title="选择加购产品"
    ok-text="确认"
    cancel-text="取消"
    width="1000px"
    @ok="sureModal"
    @cancel="close"
  >
    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange
      }"
      :columns="columns"
      :data-source="showList"
      class="ml-2x flex-grow"
      row-key="id"
    >
      <span class="red" slot="price" slot-scope="{ price }">{{
        price | money
      }}</span>

      <div slot="action" slot-scope="row">
        <!--row.relationalInfo.whetheChangeNum 禁止修改数量-->
        <div
          class="quantity-style"
          :class="{ 'not-allow': !row.relationalInfo.whetheChangeNum }"
        >
          <div class="minus-style" @click="minusAddBuy(row)">
            <a-icon type="minus" />
          </div>
          <a-input
            v-inputfocus
            v-model="row.num"
            :disabled="!row.relationalInfo.whetheChangeNum"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
            @blur="e => addInputBlur(e, row)"
            class="num-style"
          ></a-input>
          <div class="plus-style" @click="plusAddBuy(row)">
            <a-icon type="plus" />
          </div>
        </div>
      </div>
    </a-table>
  </a-modal>
</template>

<script>
import { integerReg } from "@/utils/global";

export default {
  name: "ProductAddBuy",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    // 跟着子景区走时段
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "",
          dateId: ""
        };
      }
    },

    // 分时预约选择 1.产品  2.子景区 3.景区
    appointment: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentIndex: 0,
      columns: [
        {
          title: "产品名称",
          dataIndex: "skuName",
          key: "skuName"
        },
        {
          title: "所属商户",
          dataIndex: "merchantName"
        },
        {
          title: "加购价格",
          scopedSlots: { customRender: "price" }
        },
        {
          title: "加购数量",
          key: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      showList: [],
      selectedRowKeys: [], // 选中的keys
      times: [] // 景区设置按子景区时段
    };
  },

  created() {},

  methods: {
    // 确认弹窗
    sureModal() {
      if (this.selectedRowKeys.length) {
        const addBuyList = this.showList
          .filter(item => {
            return this.selectedRowKeys.includes(item.id);
          })
          .map(temp => {
            return {
              addBuyProductId: temp.id,
              addBuyNum: temp.num
            };
          });
        this.$emit("change", { addBuyList });
        this.close();
      } else {
        this.$message.error("请勾选加购产品");
      }
    },
    close() {
      this.$emit("update:visible", false);
    },
    //
    loadData(data, back) {
      this.selectedRowKeys = []; // 初始化选中
      data.forEach(item => {
        this.$set(item, "num", item.num || 0);
        this.$set(item, "timeId", "");
        // 回显数量
        for (let temp of back) {
          if (temp.productId === item.id) {
            item.num = temp.productFlagPO.buyNum;
            item.timeIntervalId = temp.timeIntervalId;
            item.timeShareAppointmentRuleId = temp.timeShareAppointmentRuleId;
            item.timeOpen = temp.timeOpen;
            item.times = temp.times;
            // 选中回显
            if (temp.productFlagPO.whetherCheck) {
              this.selectedRowKeys.push(temp.productId);
            }
          }
        }
      });
      this.showList = data;
      this.$emit("update:visible", true);
    },
    onSelectChange(selectedRowKeys) {
      // 如果数量为0的选项，被选中则默认加一
      this.showList.forEach(item => {
        if (selectedRowKeys.includes(item.id) && item.num === 0) item.num++;
      });
      this.selectedRowKeys = selectedRowKeys;
    },

    updateData(back) {
      // 回显数量
      this.showList.forEach(item => {
        // 回显数量
        for (let temp of back) {
          if (temp.productId === item.id) {
            item.num = temp.productFlagPO.buyNum;
            break;
          }
        }
      });
    },
    // 减
    minusAddBuy(temp) {
      if (temp.relationalInfo.whetheChangeNum && temp.num > 0) {
        temp.num--;
        if (temp.num === 0) {
          let i = this.selectedRowKeys.findIndex(item => item === temp.id);
          if (i > -1) this.selectedRowKeys.splice(i, 1);
        }
      }
    },
    // 加
    plusAddBuy(temp) {
      if (temp.relationalInfo.whetheChangeNum) {
        temp.num++;
      }
      // 勾选
      if (!this.selectedRowKeys.includes(temp.id))
        this.selectedRowKeys.push(temp.id);
    },
    addInputBlur(e, row) {
      const val = e.target.value;
      if (!integerReg.test(val)) {
        this.$message.error("请输入正整数");
      } else {
        if (val > 0) {
          this.selectedRowKeys.push(row.id);
        } else {
          let i = this.selectedRowKeys.findIndex(item => item === row.id);
          if (i > -1) this.selectedRowKeys.splice(i, 1);
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
.scenic-select {
  width: 150px;
}
</style>
