<!--
  @name: 剧场购物车组件
  @author: zhengyao
  @date:2021-10-11
-->
<template>
  <div class="wrapper shopping-cart">
    <!-- 头部标题 -->
    <header class="header font-xl">待结账清单</header>
    <!-- 购物车列表-->
    <main
      class="main flex-grow"
      :class="{ 'flex-col flex-center': !cartList.id }"
    >
      <!-- 空购物车 -->
      <a-empty
        :image="emptyCar"
        :image-style="{
          width: '238px',
          height: '238px',
          margin: '150px auto 50px'
        }"
        :description="false"
        v-if="!cartList.productItemList"
      />
      <!-- 购物车列表开始 -->
      <template v-if="cartList.productItemList">
        <div
          class="l_tree_container"
          v-for="product in cartList.productItemList"
          :key="product.productId"
        >
          <div class="l_row">
            <!-- 名称标题 -->
            <div class="l_folder">
              <span class="text-1">{{ product.theaterTicketName }}</span>
              <div class="flex-start align-center flex-shrink">
                <div class="handle align-start ">
                  <a-button type="link" @click="delGoods(product, cartList.id)">
                    <span class="font-l">删除</span>
                  </a-button>
                </div>
                <span
                  >{{ product.sellingPrice | money }} x {{ product.buyNum }}
                </span>
              </div>
            </div>
            <!--开始日期时间-->
            <div
              :class="
                product.addPurchaseProductItemList.length
                  ? ['dashed', 'ml-23']
                  : ''
              "
              class="flex-between font-n gray align-center"
            >
              <div>
                <p>{{ product.theaterTicketName }}</p>
                <p>
                  {{ product.appointmentBeginTime | date("YYYY-MM-DD hh:mm") }}
                </p>
                <p class="text-1">{{ product.placeName }}</p>
              </div>
              <!-- <p
                class="flex-shrink"
                v-show="!product.addPurchaseProductItemList.length"
              >
                {{ product.sellingPrice | money }} x {{ product.buyNum }}
              </p> -->
            </div>
            <!-- 区域座位 -->
            <div
              :class="{
                'ml-23': product.addPurchaseProductItemList.length
              }"
              class=" l_folder  font-n"
            >
              <p class="gray text-1">
                {{ product.theaterSeatInfo | setInfo }}
              </p>
              <div
                class="flex-start align-center flex-shrink"
                v-show="!product.addPurchaseProductItemList.length"
              >
                <span
                  class="font-l primary pointer"
                  @click="onAgainSelect(product)"
                  >重新选座</span
                >
                <span class="ml-2x text-1"
                  >{{ product.settlementPrice | money }}
                </span>
              </div>
            </div>
            <!--加购推荐产品 -->
            <ul
              class="l_tree"
              v-if="product.whetherRecommend && product.whetherRecommend"
            >
              <li class="l_tree_branch">
                <div
                  class=" flex-start align-center"
                  @click="addPurchase(product)"
                >
                  <div class="l_tree_children_btn">+</div>
                  <span class="pointer">加购推荐产品</span>
                  <p class="ml-2x blue pointer font-l">选择</p>
                </div>
                <ul
                  class="l_tree"
                  v-show="product.addPurchaseProductItemList.length"
                >
                  <li
                    class="l_tree_branch"
                    v-for="som in product.addPurchaseProductItemList"
                    :key="som.productId"
                  >
                    <div class="">
                      <a-row>
                        <a-col :span="10">
                          <span class="text-1">{{ som.productName }}</span>
                        </a-col>
                        <a-col :span="10">
                          <span class="text-1">x{{ som.buyNum }}</span></a-col
                        >
                        <!-- <a-col :span="5">
                          <span
                            class="primary pointer"
                            @click="addPurchase(product)"
                            >删除
                          </span>
                        </a-col> -->
                        <a-col :span="4">
                          {{ som.settlementPrice | money }}
                        </a-col>
                      </a-row>
                    </div>
                  </li>
                </ul>
                <div
                  class="mt-1x flex-end"
                  v-show="product.addPurchaseProductItemList.length"
                >
                  <span
                    class="primary mr-2x pointer"
                    @click="onAgainSelect(product)"
                    >重新选座</span
                  >
                  <span class="red text-1">
                    {{ totalAdditional | money }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <!-- 购物车列表结束-->
    </main>
    <!-- 购物车底部按钮 -->
    <footer class="shadow bg-white">
      <div class="align-center flex-between ph-3x pt-x">
        <span class="gray">共{{ cartList.ticketNum || 0 }}项 合计</span>
        <span class="font-xxl">{{
          (cartList.ticketNum ? cartList.totalPrice || 0 : 0) | money
        }}</span>
      </div>
      <div class="under flex-between">
        <a-button
          v-permission="'appTheaterListWholeordercancellation'"
          class="flex-grow"
          size="large"
          shape="round"
          :disabled="!cartList.ticketNum"
          @click="cancelAll"
          radiu
          >整单取消</a-button
        >
        <a-button
          v-permission="'appTheaterListCheckout'"
          class="flex-start align-center  ml-2x font-n"
          type="primary"
          size="large"
          shape="round"
          @click="checkOut"
          :disabled="!cartList.ticketNum"
        >
          <span class="font-n">登记信息并结账：</span>
          <span class="font-n text-1 w60">
            {{ cartList.totalPrice || 0 | money }}</span
          >
        </a-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { onkeydown } from "@/utils/global";
import {
  theaterDetail,
  theaterMore,
  deleteOrCancel,
  theaterSettleAccounts
} from "@/api/cart";
export default {
  name: "ShoppingCart",
  data() {
    return {
      theaterCartId: sessionStorage.getItem("theaterCartId"), //购物车id
      cartList: {}, //购物车列表
      emptyCar: require("../../../assets/images/empty_car.png")
    };
  },
  created() {
    this.getTheaterDetail();
  },
  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown);
  },
  beforeDestroy() {
    //关闭键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown);
  },
  computed: {
    ...mapGetters({
      doingAddPurchase: "ticketSales/getdoingAddPurchase",
      shortcutKey: "setting/getshortcutKey"
    }),
    //加购产品价格合计
    totalAdditional() {
      let list = this.cartList.productItemList,
        total = 0;
      if (list.length) {
        list.forEach(li => {
          total = li.addPurchaseProductItemList.reduce((n, item) => {
            return (n += item.settlementPrice);
          }, 0);
        });
      }
      return total;
    }
  },
  filters: {
    //过滤区域座位
    setInfo(theaterSeatInfo) {
      let info = [...new Set(theaterSeatInfo.map(item => item.areaName))],
        collection = [],
        str = "";
      info.forEach(item => {
        collection.push({
          name: item,
          arr: theaterSeatInfo.filter(list => list.areaName == item)
        });
      });
      collection.forEach(item => {
        str +=
          item.name + " " + item.arr.map(li => li.seatName).join(",") + " ";
      });
      return str;
    }
  },
  methods: {
    ...mapActions("order", ["setTouristInfo", "setCartList"]),
    //重新选座
    onAgainSelect({
      theaterTicketId,
      theaterTicketSpuId,
      theaterSeatInfo,
      spuId
    }) {
      let data = [
        {
          theaterTicketId,
          theaterTicketSpuId,
          seatIds: theaterSeatInfo.map(item => item.seatId)
        }
      ];
      this.$parent.selectSpuId = spuId;
      this.$parent.$refs.selectSeat.backData(data, spuId);
    },
    windowKeyDown(e) {
      if (!this.shortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    // 根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      switch (code) {
        case 13:
          //结账
          this.checkOut();
          break;
        default:
          break;
      }
    },
    //购物车详情
    getTheaterDetail() {
      let id = sessionStorage.getItem("theaterCartId");
      //没有购物车，不做查询
      if (!id) {
        return false;
      }
      theaterDetail({ id })
        .then(res => {
          if (Object.keys(res).length) {
            this.cartList = res;
            sessionStorage.setItem("theaterCartId", res.id);
            this.theaterCartId = res.id;
          } else {
            this.cartList = [];
            sessionStorage.removeItem("theaterCartId");
            this.theaterCartId = "";
          }
        })
        .catch(() => {});
    },
    //删除当前剧目
    delGoods(row, id) {
      let data = {
        id,
        productId: row.productId,
        theaterList: null
      };
      this.updateCart(data);
    },
    //选择加购
    addPurchase(row) {
      row.theaterCartId = this.theaterCartId;
      this.$parent.purchaseObj = row;
      this.$parent.showPurchase = true;
    },
    //整单取消
    cancelAll() {
      this.$confirm({
        title: "清空购物车",
        content: "确定要清空购物车吗？",
        onOk: () => {
          deleteOrCancel({
            id: this.theaterCartId
          })
            .then(() => {
              this.getTheaterDetail();
            })
            .catch(() => {});
        },
        onCancel() {}
      });
    },
    //更新购物车
    updateCart(data) {
      theaterMore(data)
        .then(() => {
          this.getTheaterDetail();
        })
        .catch(() => {});
    },
    //结账
    checkOut() {
      theaterSettleAccounts({
        id: this.cartList.id
      })
        .then(res => {
          this.$store.commit("order/setCartList", res);
          //不需要填写购票人和观影人
          if (!res.needVisitorInfo) {
            this.$router.push({
              path: "/home/sell/OrderInfo"
            });
          } else {
            //需要填写购票人和观影人
            this.saveTourisInfo(res);
            this.$router.push("/home/sell/RegisterTouristInfo");
          }
        })
        .catch(() => {});
    },
    //保存出游人资料到vuex
    saveTourisInfo(res) {
      let visitorsList = [];
      res.productItemList.forEach(item => {
        let i = item.buyNum;
        while (i > 0) {
          let newItem = JSON.parse(JSON.stringify(item)); //deepclone
          //拆分成多个出游人信息
          if (newItem.travelerList !== null) {
            visitorsList.push(newItem);
          }
          i--;
        }
      });
      this.setTouristInfo({
        visitorsList: visitorsList,
        purchaserMemberInfo: res.purchaserMemberInfo
      });
    },
    //回车结账
    keyDown(e) {
      if (e.keyCode === 13) {
        this.checkOut();
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.w50 {
  width: 50%;
}
.ml-23 {
  margin-left: 23px;
}
.dashed ::before {
  content: "";
  width: 2px;
  height: calc(60% - 4px);
  display: block;
  border-left: 1px dashed rgb(153 153 153);
  background-color: rgb(255 255 255);
  position: absolute;
  // bottom: 57px;
  left: 22px;
}
// 去掉了number的默认样式
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.shopping-cart {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  width: 364px;
  margin-left: 0;
  .main {
    overflow-y: auto;
    height: 100px;
    // padding: 0 16px 8px;
    font-size: 16px;
    /deep/.ant-btn-link {
      padding: 0 0 0 16px;
      font-size: 18px;
      line-height: 1;
    }
  }
}
.l_tree_container {
  width: 100%;
  position: relative;
  // padding-top: 16px;
  // padding-bottom: 12px;
  border-bottom: 1px solid #ececec;
  &:last-child {
    // border: none;
  }
}
.l_tree {
  width: 100%;
  height: 100%;
  padding-left: 14px;
  // padding-top: 20px;
}
.l_tree_branch {
  position: relative;
  display: block;
  width: 100%;
  padding: 6px 0 0 12px;
}
.l_folder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .region {
    width: 55%;
  }
}
.l_tree_children_btn {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #999;
  color: #999;
  outline: none;
  cursor: pointer;
  margin-right: 5px;
}
.l_row {
  position: relative;

  padding: 12px;
  .l_tree_children_btn + .l_folder {
    position: absolute;
    top: -3px;
    left: 30px;
  }
}
ul.l_tree:before {
  content: "";
  border-left: 1px dashed #999999;
  height: calc(100%);
  position: absolute;
  top: 0;
  left: 10px;
}

.l_tree .l_tree_branch:last-child::before {
  content: "";
  width: 4px;
  height: calc(100% - 16px);
  display: block;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: -6px;
}

.l_tree,
.l_tree_branch {
  position: relative;
}
.l_tree_branch::after {
  content: "";
  width: 12px;
  height: 0;
  border-bottom: 1px dashed #999999;
  position: absolute;
  right: calc(100% - 9px);
  top: 16px;
}
.text-2 {
  width: 100%;
}
.handle {
  width: 60px;
}

.under {
  padding: 4px 16px 24px;
  .w60 {
    width: 60px;
  }
}

@media screen and (max-width: 1366px) {
  .f-key {
    display: none;
  }
}
</style>
