<!--座位列表组件-->
<template>
  <!--座位-->
  <div class="seat-wrapper">
    <!--选座-->
    <div class="content" :style="{ height, width: stageWidth }">
      <!--舞台-->
      <header class="header" v-show="horizontalNums.length">
        <div class="stage">6厅 （需自备3D眼镜）</div>
      </header>

      <div class="flex-start bottom-row">
        <!--左边灰色编号列-->
        <ul class="left-col">
          <li
            class="number number-v"
            v-for="(item, index) in verticalNums"
            :key="'v' + index"
            @click="checkRow(item, index)"
          >
            {{ item || "道" }}
          </li>
        </ul>
        <!--座位列表-->
        <ul>
          <li v-for="(item, index) in seatList" :key="index" class="flex-start">
            <section
              class="set-box flex-shrink"
              :class="{ sell: temp.productSaleStatus === 1 }"
              v-for="(temp, idx) in item"
              :key="idx"
              :title="temp.productName"
              @click="checkSet(item, temp)"
            >
              <!--productSaleStatus:1可售，2过道，3不可售, 4已售，5 预留-->
              <!--禁用、不可售-->
              <i
                class="iconfont iconhongse disabled"
                v-if="temp.productSaleStatus === 3"
              ></i>
              <!--已售-->
              <i
                class="iconfont iconhongse  sold"
                v-else-if="temp.productSaleStatus === 4"
              ></i>
              <!--选中-->
              <i class="iconfont iconlvse checked" v-else-if="temp.checked"></i>
              <!--可选-->
              <i
                class="iconfont iconkuan choosable"
                v-else-if="temp.productSaleStatus === 1"
              ></i>
            </section>
          </li>
        </ul>
      </div>
    </div>

    <footer class="text-c status">
      <!--座位状态-->
      <ul class="flex-around ph-4x">
        <!--productSaleStatus:1可售，2过道，3不可售, 4已售，5 预留-->
        <!--可选-默认-->
        <li>
          <i class="iconfont iconkuan choosable"></i>
          <span class="text-v font-l ml-x">可选</span>
        </li>
        <!--选中-->
        <li>
          <i class="iconfont iconlvse checked"></i>
          <span class="text-v font-l ml-x ">已选</span>
        </li>
        <!--已售-->
        <li>
          <i class="iconfont iconhongse  sold"></i>
          <span class="text-v font-l ml-x ">已售</span>
        </li>
        <!--禁用、不可售-->
        <li>
          <i class="iconfont iconhongse disabled"></i>
          <span class="text-v font-l ml-x">禁用</span>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
export default {
  name: "SeatListNew",
  props: {
    // 排编号
    horizontalNums: {
      type: Array,
      default() {
        return [];
      }
    },
    // 列编号
    verticalNums: {
      type: Array,
      default() {
        return [];
      }
    },
    // 座位列表
    seatList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 票档
    levelList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      height: "500px"
    };
  },
  created() {
    let height = window.innerHeight;
    if (height < 800) this.height = 400 + "px";
  },
  computed: {
    // 舞台宽度
    stageWidth() {
      return this.horizontalNums.length * 42 + 88 + "px";
    }
  },
  methods: {
    // 单个选座
    checkSet(item, temp) {
      if (temp.productSaleStatus === 1) {
        temp.checked = !temp.checked;
        this.calcChecked();
      }
    },
    // 选择全部
    checkAll() {
      // 查找是否有未选中
      let some = this.seatList.some(item => {
        return item.some(temp => temp.productSaleStatus === 1 && !temp.checked);
      });
      this.seatList.map(item => {
        item.forEach(temp => {
          if (temp.productSaleStatus === 1) {
            temp.checked = some;
          }
        });
      });
      this.calcChecked();
    },
    // 选择列
    checkCol(num, index) {
      if (!num) return;
      // 只要有一个未选中就整列选中，否则整列不选中
      let some = this.seatList.some(item => {
        return !item[index].checked && item[index].productSaleStatus === 1;
      });

      this.seatList.forEach(item => {
        if (item[index].productSaleStatus === 1) {
          item[index].checked = some;
        }
      });
      this.calcChecked();
    },
    // 选择行
    checkRow(num, index) {
      if (!num) return;
      // 只要有一个未选中就整行选中，否则整行不选中
      const obj = this.seatList[index];
      let some = obj.some(
        item => !item.checked && item.productSaleStatus === 1
      );
      obj.forEach(item => {
        if (item.productSaleStatus === 1) {
          item.checked = some;
        }
      });
      this.calcChecked();
    },
    // 计算已选中
    calcChecked() {
      const arr = [];
      this.seatList.map(item => {
        item.forEach(temp => {
          if (temp.checked) {
            arr.push(temp);
          }
        });
      });
      // 返回选中的座位
      this.$emit("change", arr);
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../../assets/less/custom";
.iconfont {
  font-size: 22px;
  vertical-align: middle;
}

/*座位状态*/
/*可选*/
.choosable {
  color: #999;
}
/*已选*/
.checked {
  color: #21e181;
}
/*已售*/
.sold {
  color: #f75a41;
}
/*禁用*/
.disabled {
  color: #ccc;
}

/*座位*/
.seat-wrapper {
  background: #f0f0f0;
  padding: 0 16px 16px;
  .content {
    position: relative;
    margin: 0 auto;
    max-width: 936px;
    height: 500px;
    overflow: auto;

    .header {
      position: sticky;
      top: 0;
      left: auto;
      z-index: 1;
      margin-bottom: 18px;
      padding-left: 70px;
      width: 100%;
      /*舞台*/
      .stage {
        width: 80%;
        height: 33px;
        line-height: 33px;
        background: url("../../../../assets/images/theater-screen.png");
        background-size: 100% 100%;
        text-align: center;
        margin: 0 auto;
      }
      > ul {
        opacity: 0;
      }
    }
    .number {
      width: 32px;
      height: 42px;
      padding-bottom: 10px;
      box-sizing: border-box;
      line-height: 32px;
      text-align: center;
      flex-shrink: 0;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
      user-select: none;
      &:hover {
        border-color: @primary;
        color: @primary;
      }
    }
    .all-col {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 10;
    }
    .left-col {
      position: sticky;
      left: 0;
      top: auto;
      margin-right: 48px;
      margin-top: -10px;
      padding-top: 10px;
      background: #c3c3c3;
      border-radius: 12px;
    }
    /*.header,*/
    .bottom-row {
      width: max-content;
    }

    /*座位*/
    .set-box {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      line-height: 32px;
      text-align: center;
      flex-shrink: 0;
      cursor: not-allowed;
      margin-right: 10px;
      margin-bottom: 10px;
      &.sell {
        cursor: pointer;
      }
    }
  }

  .status {
    margin-top: 16px;
  }
}
</style>
