<!-- 剧场加购列表 -->
<template>
  <div class="wrapper flex-col flex-start flex-grow">
    <header class="header">
      <span class="font-l">选择加购推荐产品</span>
      <a-icon class="font-xxl pointer gray" type="close" @click="close" />
    </header>
    <section class="main p-2x flex-start mt-2x">
      <!--左-->
      <ul class="nav shadow ph-2x">
        <li
          :class="{ on: currentIndex === 'All' }"
          @click="currentIndex = 'All'"
        >
          全部
        </li>
        <li
          v-for="(item, index) in classify"
          :key="item.categoryId"
          :class="{ on: index === currentIndex }"
          @click="onSelectClassify(index)"
        >
          {{ item.categoryName }}
        </li>
      </ul>
      <!--右-->
      <a-table
        :columns="columns"
        :data-source="showList"
        class="ml-2x flex-grow"
        row-key="id"
      >
        <span class="red" slot="price" slot-scope="{ price }">{{
          price | money
        }}</span>
        <div slot="action" slot-scope="row">
          <div class="quantity-style">
            <div class="minus-style" @click="reduceNum(row.id)">
              <a-icon type="minus" />
            </div>
            <a-input
              v-model="row.count"
              class="num-style"
              @blur="verify(row.id)"
            ></a-input>
            <div class="plus-style" @click="addNum(row.id)">
              <a-icon type="plus" />
            </div>
          </div>
        </div>
      </a-table>
    </section>
    <footer class="footer">
      <a-button
        type="primary"
        ghost
        @click="close"
        size="large"
        shape="round"
        class="btn-lg"
        >取消</a-button
      >
      <a-button
        size="large"
        shape="round"
        type="primary"
        class="ml-3x btn-lg"
        @click="doAdd"
        >确定</a-button
      >
    </footer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { clearRepeatArrayObject } from "@/assets/js/util";
import { addPurchaseProduct, theaterAddBuy } from "@/api/cart";

const columns = [
  {
    title: "产品名称",
    dataIndex: "productName"
  },
  {
    title: "所属商户",
    dataIndex: "merchantName"
  },
  {
    title: "加购价格",
    scopedSlots: { customRender: "price" }
  },
  {
    title: "加购数量",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "AdditionalPurchase",
  props: {
    purchaseObj: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      product: { addPurchaseProductItemList: [] },
      list: [], // 全部加购产品
      classify: [], //所有分类
      currentIndex: 0,
      pid: "",
      columns
    };
  },

  created() {
    //监听
    this.$bus.on("addPurchase", res => {
      this.$emit("open");
      this.product = res;
    });
    this.getList();
  },
  computed: {
    ...mapGetters("order", ["cartList"]),
    showList() {
      //当前分类下显示的列表
      if (this.currentIndex === "All") return this.list;
      return this.list.filter(
        item => item.categoryId === this.classify[this.currentIndex].categoryId
      );
    }
  },
  methods: {
    ...mapActions("order", ["setCartList"]),
    //选择分类
    onSelectClassify(index) {
      this.currentIndex = index;
    },
    //加购列表
    getList() {
      let row = this.purchaseObj;
      let {
        theaterTicketId,
        theaterTicketSpuId,
        playDate,
        theaterCartId
      } = this.purchaseObj;
      addPurchaseProduct({
        id: theaterTicketId,
        spuId: theaterTicketSpuId,
        dailyCalendar: playDate,
        cartId: theaterCartId
      }).then(res => {
        //如果本来已经存在加购列表，则把count加上去
        if (row.addPurchaseProductItemList) {
          res.forEach(item => {
            item.count = 0;
            //通过过滤匹配相同产品
            let target = row.addPurchaseProductItemList.filter(son => {
              return son.productId === item.id;
            });
            if (target.length > 0) {
              item.count = target[0].buyNum;
            }
          });
        } else {
          res.forEach(item => {
            item.count = 0;
          });
        }
        this.list = res;
        this.classify = clearRepeatArrayObject(
          res.map(item => ({
            categoryName: item.categoryName,
            categoryId: item.categoryId
          })),
          "categoryId"
        );
      });
    },
    //加购数量减
    reduceNum(id) {
      let index = this.list.findIndex(item => {
        return item.id == id;
      });
      let list = this.list[index];
      if (list.count <= 0) {
        list.count = 0;
      } else {
        list.count--;
      }
    },
    //加购数量加
    addNum(id) {
      let index = this.list.findIndex(item => {
        return item.id == id;
      });
      let list = this.list[index];
      list.count++;
    },
    //加购数量手动输入
    verify(id) {
      let index = this.list.findIndex(item => {
        return item.id == id;
      });
      let list = this.list[index];
      if (list.count < 0) {
        list.count = 0;
      }
    },
    //右上角关闭
    close() {
      this.$emit("close");
    },
    //确定添加加购
    doAdd() {
      theaterAddBuy({
        id: this.purchaseObj.theaterCartId, //购物车id
        productId: this.purchaseObj.productId,
        addProductList: this.list.map(item => ({
          productId: item.id,
          spuId: item.spuId,
          buyNum: item.count
        }))
      })
        .then(res => {
          this.$emit("close");
          this.setCartList(res);
        })
        .catch(() => {});
    }
  },
  //销毁监听事件
  destroyed() {
    this.$bus.off("addPurchase");
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
// 去掉了number的默认样式
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.nav {
  width: 180px;
  border-radius: 8px;
  font-size: 16px;
  li {
    list-style: none;
    padding: 16px 0;
    cursor: pointer;
    border-bottom: 1px solid @border;
    &.on {
      color: @primary;
    }
    &:last-child {
      border: none;
    }
  }
}
</style>
