<template>
  <!--  剧目轮播-->
  <div class="banner-wrapper">
    <div v-show="list.length">
      <swiper class="swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide
          v-for="(item, index) in list"
          :key="index"
          class="flex-center align-center"
        >
          <div class="swiper-item" :class="{ active: item.active }">
            <img class="img" :src="item.skuImages" alt="" />
          </div>
        </swiper-slide>
        <!-- Optional controls -->
        <!--<div class="swiper-pagination" slot="pagination"></div>-->
        <!--<div class="swiper-button-prev" slot="button-prev"></div>-->
        <!--<div class="swiper-button-next" slot="button-next"></div>-->
        <!--<div class="swiper-scrollbar" slot="scrollbar"></div>-->
      </swiper>

      <!--激活描述内容-->
      <div class="active-info">
        <p>
          <b class="title">{{ currentItem.name }}</b>
          <span class="fen"
            ><b>{{ currentItem.score }}</b> <span class="font-n">分</span></span
          >
        </p>
        <p class="mt-x">
          {{ currentItem.duration }}分钟
          <template v-if="currentItem.playLabelNames">
            |
            <span
              v-for="(item, index) in currentItem.playLabelNames"
              :key="item + index"
              >{{ item }}
            </span>
          </template>
          <template v-if="currentItem.actorsNames">
            |
            <span
              v-for="(item, index) in currentItem.actorsNames"
              :key="item + index"
              >{{ item }}
            </span>
          </template>
        </p>
      </div>
    </div>

    <!--空-->
    <a-empty
      v-show="loaded && list.length === 0"
      :image="simpleImage"
      class="white mt-4x pt-4x"
    />

    <!--高斯模糊背景-->
    <div class="filter-image">
      <img :src="currentItem.skuImages" alt="" />
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";

import { swiper, swiperSlide } from "vue-awesome-swiper";
import { productFrontPlayList } from "../../../../api/search";
import { Empty } from "ant-design-vue";
export default {
  name: "ProductCarousel",

  components: {
    swiper,
    swiperSlide
  },

  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      loaded: false,
      currentIndex: 0, // 激活的图片下标
      currentItem: {
        playLabelNames: [],
        actorsNames: []
      }, // 激活的项目
      notNextTick: true,
      swiperOption: {
        slidesPerView: 7, // 预览个数
        direction: "horizontal",
        centeredSlides: true, // 居中布局
        slideToClickedSlide: true, // 点击滚动到该项
        grabCursor: true,
        observer: true,
        observeParents: true,
        // 事件监听
        on: {
          slideChange: () => {
            if (this.list.length) {
              const index = this.swiper.realIndex;
              this.currentIndex = index;
              this.currentItem = this.list[index];
              this.list.forEach(temp => {
                temp.active = false;
              });
              this.currentItem.active = true;
              this.change();
            }
          }
        },
        // 响应式设置
        breakpoints: {
          1280: {
            //当屏幕宽度大于等于1280
            slidesPerView: 4
          },
          1600: {
            //当屏幕宽度大于等于1600
            slidesPerView: 5
          },
          1920: {
            //当屏幕宽度大于等于1600
            slidesPerView: 7
          }
        }
      },
      // 后续删除
      list: []
    };
  },

  // you can find current swiper instance object like this, while the notNextTick property value must be true
  // 如果你需要得到当前的swiper对象来做一些事情，你可以像下面这样定义一个方法属性来获取当前的swiper对象，同时notNextTick必须为true
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },

  methods: {
    loadData(subScenicId) {
      this.loaded = false;
      productFrontPlayList({
        subScenicId
      })
        .then(res => {
          if (res.length) {
            res.forEach(item => {
              item.active = false;
              item.score = item.score.toFixed(1);
            });
            this.list = res;
            // you can use current swiper instance object to do something(swiper methods)
            // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
            // console.log("this is current swiper instance object", this.swiper);
            let center = Math.floor(this.list.length / 2);
            this.currentIndex = center;
            this.currentItem = this.list[center];
            this.currentItem.active = true;
            this.swiper.slideTo(center, 1000, false);
          } else {
            this.currentItem = {
              playLabelNames: [],
              actorsNames: []
            };
            this.list = [];
          }
          this.loaded = true;
        })
        .catch(() => {});
    },
    // 改变
    change() {
      this.$emit("change", this.currentItem.spuId);
    }
  }
};
</script>

<style scoped lang="less">
.banner-wrapper {
  overflow: hidden;
  position: relative;
  height: 290px;
  width: 100%;
  background: #757575;
  .swiper {
    height: 220px;
    .swiper-item {
      position: relative;
      width: 140px;
      height: 190px;
      transform: scale(0.85);
      transition: transform 0.1s linear;
      &.active {
        transform: scale(1);
        .img {
          border-color: #fff;
        }
      }
      .img {
        border: 2px solid transparent;
        width: 100%;
        height: 100%;
      }
    }
  }

  .active-info {
    user-select: none;
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
    text-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    .title,
    .fen {
      font-size: 18px;
    }
    .title {
      text-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    }
    .fen {
      margin-left: 8px;
      color: #ff952a;
    }
  }

  .filter-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(40px);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
