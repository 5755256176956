<template>
  <div>
    <!--门票列表-->
    <section class="section ticket-box" v-if="list.length">
      <a-row class="ticket-list" :gutter="16">
        <a-col :xxl="8" :xl="12" v-for="(item, index) in list" :key="index">
          <div
            class="ticket-list-item has-shortcut-key flex-start"
            @click="openSelect(item)"
          >
            <div class="img flex-center flex-col flex-shrink">
              <p class="bold text-c">
                {{ item.beginTime }}
              </p>
              <p class="tag text-c">{{ item.endTime }}散场</p>
            </div>
            <div class="right-content flex-grow flex-between flex-col">
              <p
                class="bold text-1 font-n res mb-x"
                style="margin-bottom: 6px;"
                :title="item.theaterName"
              >
                {{ item.theaterName }}
              </p>
              <p class="tag text-1">{{ item.placeName }}</p>
              <div class="flex-start mt-x">
                <span class="font-xs">销售价：</span>
                <b class="price">{{ item.sellingPrice | money }}</b>
                <del class="font-s disabled ml-1x">{{
                  item.marketPrice | money
                }}</del>
              </div>
              <!--快捷键-->
              <div class="fast-key flex-start">
                <div class="del" @click.stop="onClickSetting(item, index)">
                  <i class="iconfont iconhuabanfuben mr-x"></i>
                  <span>设置</span>
                </div>
                <div
                  @click="setFastKey(index)"
                  v-if="shortcutKey && index < 6"
                  class="buy ml-1x"
                >
                  按{{ keyCode[getSettings.TicketOrderFastKey[index]] }}购买
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </section>
    <!--空-->
    <a-empty v-else :image="simpleImage" />
    <!--设置快捷键-->
    <a-modal
      title="请直接在键盘上输入新的快捷键"
      :visible="visibleFastKey"
      width="300px"
      @ok="fastKeyOk"
      @cancel="fastKeyCancel"
    >
      <h3 class="text-c">{{ currentKey }}</h3>
    </a-modal>
    <!-- 产品设置弹窗 -->
    <a-modal
      class="modal"
      v-model="isShowtSettingModal"
      title="设置"
      :width="600"
      @ok="onClickSaveProductSetting"
      @cancel="onClickCancelProductSetting"
      :maskClosable="false"
    >
      <section class="modal-item">
        <span class="modal-item-title margin-left">取票操作：</span>

        <div class="modal-item-content">
          <div
            class="content flex-between align-center pb-2x"
            v-for="(item, index) in takeTicketSettingList"
            :key="index"
          >
            <div class="product-setting-name">{{ item.name }}</div>
            <div class="flex-between align-center">
              <span
                v-if="item.value === currentTakeTicketSetting"
                class="primary"
                >已设默认</span
              >
              <span
                v-else
                class="pointer"
                @click="changeTakeTicketDefault(index)"
                >设为默认</span
              >
            </div>
          </div>
        </div>
      </section>
    </a-modal>
    <!--选座弹窗-->
    <select-seat-new
      :visible.sync="visibleSeat"
      ref="selectSeat"
      @sure="addCart"
    ></select-seat-new>
  </div>
</template>

<script>
import { Empty } from "ant-design-vue";
import { productFrontSaleSessionTicket } from "../../../../api/search";
import { cartTheater, ticketSpecialInfo } from "../../../../api/cart";
import { mapGetters } from "vuex";
import keyCode from "../../../../utils/keycode";
import SelectSeatNew from "./SelectSeatNew";

export default {
  name: "ProductList",

  components: {
    SelectSeatNew
  },

  props: {
    subScenicId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      // 快捷键
      keyCode,
      page: 1,
      size: 99,
      list: [], // 产品列表
      cartId: 0, // 购物车id
      fastKeyI: 0, // 当前快捷键下标
      currentCode: "",
      visibleFastKey: false,
      currentKey: "", // 当前快捷键key
      isShowtSettingModal: false, // 是否显示设置窗口
      setProductId: "", // 设置产品有效期产品id
      currentProductIndex: null, //当前设置的产品索引
      showProductValiditySetting: [], // 单个产品的产品有效期设置
      currentTakeTicketSetting: 0, //当前产品取票操作的值
      takeTicketSettingList: [
        {
          name: "无操作",
          value: 0
        },
        {
          name: "取票后自动核销",
          value: 1
        }
      ],

      visibleSeat: false, // 选座弹窗
      checkedTicket: {} // 选中的票
    };
  },
  computed: {
    ...mapGetters({
      // 页面布局
      getSettings: "setting/getSettings",
      shortcutKey: "setting/getshortcutKey"
    }),
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    }
  },
  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown, true);
  },

  //关闭键盘监听
  beforeDestroy() {
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
  },
  methods: {
    // 产品列表
    loadData(params) {
      this.page = 1;
      this.params = params;
      productFrontSaleSessionTicket({
        ...params
      })
        .then(res => {
          // res.forEach(item => {
          //   item.defaultValidityRule = item.validDefault || 1; // 初始化
          //   item.sellingPrice = item.sellingPrice / 100;
          //   item.oldPrice = item.sellingPrice;
          //   item.buyNum = 0;
          // });
          this.list = res;
          // 查找产品的有效期
          // this.findProductValidity();
        })
        .catch(() => {});
    },
    // 打开选座弹窗
    openSelect(item) {
      this.$refs.selectSeat.loadData(item);
      this.checkedTicket = item;
    },
    // 添加到购物车
    addCart(item) {
      cartTheater({
        productId: this.checkedTicket.id, // 场次id
        subScenicId: this.subScenicId,
        writeOffRightNow: 0, //是否立即核销 0，1立即核销
        ...item
      })
        .then(res => {
          this.$emit("update-cart", res);
        })
        .catch(() => {});
    },
    // 产品设置
    onClickSetting(item, index) {
      if (this.cartId) {
        this.$message.warning("请清空购物车再进行产品设置");
        return;
      }
      this.setProductId = item.id;
      this.currentProductIndex = index;

      this.getTakeTicketSetting(item);

      this.isShowtSettingModal = true;
    },

    //获取单个产品的取票操作
    getTakeTicketSetting(item) {
      //默认是无操作
      item.writeOffRightNow = 0;

      const takeTicketSettingStore = this.getSettings.ProductTakeTicket;
      if (takeTicketSettingStore.length) {
        takeTicketSettingStore.forEach(storeItem => {
          if (storeItem.setProductId === item.id) {
            item.writeOffRightNow = storeItem.type;
          }
        });
      }

      this.currentTakeTicketSetting = item.writeOffRightNow;
    },
    //点击退出产品设置
    onClickCancelProductSetting() {
      this.isShowtSettingModal = false;
      //还原默认值
      this.currentTakeTicketSetting = 0;
      this.currentProductIndex = null;
      this.showProductValiditySetting = [];
    },
    //点击保存产品设置
    onClickSaveProductSetting() {
      this.saveProductSetting();
      this.isShowtSettingModal = false;
      //还原默认值
      this.currentTakeTicketSetting = 0;
      this.currentProductIndex = null;
      this.showProductValiditySetting = [];
    },

    //保存产品设置
    saveProductSetting() {
      //公共变量赋值
      let productId = this.setProductId;
      let productIndex = this.currentProductIndex;
      let currentTopMenuKey = this.currentTopMenuKey;
      let currentTakeTicketSetting = this.currentTakeTicketSetting;
      let cardId = this.cartId;

      let defaultValidIndex = 0;
      this.showProductValiditySetting?.find((item, itemIndex) => {
        if (item?.isDefault === true) {
          defaultValidIndex = itemIndex;
        }
        return item?.isDefault === true;
      });

      let defaultValidIndexPlusOne = defaultValidIndex + 1;
      //保存产品有效期设置
      let validValue = {
        setProductId: productId,
        idx: defaultValidIndexPlusOne
      };
      // 查询该产品是否设置有效期
      let productValidityRule = this.getSettings.ProductValidityRule;
      let isSetValid = false;
      if (productValidityRule.length) {
        productValidityRule.forEach(item => {
          // 设置过的产品就编辑产品有效期
          if (item.setProductId === productId) {
            item.idx = defaultValidIndexPlusOne;
            isSetValid = true;
          }
        });
      }

      const productItem = this.list.find(listk => {
        return listk.id === productId;
      });
      // 加入购物车后再更改产品有效期
      if (productItem?.buyNum > 0) {
        let productRule = this.checkProductRule(productItem);
        productRule.productId = productId;
        productRule.cartId = cardId;
        ticketSpecialInfo(productRule)
          .then({})
          .catch(() => {});
      }

      // 没设置过的产品就添加
      if (!isSetValid) {
        productValidityRule.push(validValue);
      }

      // 存缓存
      localStorage.setItem(
        `${currentTopMenuKey + "ProductValidityRule"}`,
        JSON.stringify(productValidityRule)
      );
      this.$store.commit(
        `setting/set${currentTopMenuKey + "ProductValidityRule"}`,
        productValidityRule
      );

      //保存取票操作
      let takeTicketSettingStore = this.getSettings.ProductTakeTicket;
      let isSetTicket = false;

      if (takeTicketSettingStore.length) {
        takeTicketSettingStore.forEach(item => {
          // 设置过的就编辑取票操作
          if (item.setProductId === productId) {
            item.type = currentTakeTicketSetting;
            isSetTicket = true;
          }
        });
      }

      let takeTicketSetting = {
        setProductId: productId,
        type: currentTakeTicketSetting
      };

      // 没设置过的就添加取票操作
      if (!isSetTicket) {
        takeTicketSettingStore.push(takeTicketSetting);
      }

      // 存缓存
      localStorage.setItem(
        `${currentTopMenuKey + "ProductTakeTicket"}`,
        JSON.stringify(takeTicketSettingStore)
      );
      this.$store.commit(
        `setting/set${currentTopMenuKey + "ProductTakeTicket"}`,
        takeTicketSettingStore
      );

      //设置默认有效期 defaultValidityRule
      let currentProduct = this.list[productIndex];
      // 重置默认产品有效期
      let defaultValidityRule = currentProduct.validDefault;
      // 获取窗户设置产品有效期
      if (this.getSettings.ProductValidityRule) {
        let productRuleObj = this.getSettings.ProductValidityRule.find(
          val => val.setProductId === productId
        );
        if (productRuleObj) {
          // 窗口设置为最后一条规则，后台删了最后一条规则，就重新默认为后台设置的默认
          if (productRuleObj.idx <= currentProduct.validRulesList.length) {
            defaultValidityRule = productRuleObj.idx;
          }
        }
      }
      currentProduct.defaultValidityRule = defaultValidityRule;
      currentProduct.writeOffRightNow = currentTakeTicketSetting;

      this.$set(this.list, productIndex, currentProduct);
    },
    //改变产品有效期
    changeProductValidDefault(index) {
      this.showProductValiditySetting?.forEach((item, itemIndex) => {
        if (index == itemIndex) {
          item.isDefault = true;
        } else {
          item.isDefault = false;
        }
        this.$set(this.showProductValiditySetting, itemIndex, item);
      });
    },

    //改变取票操作
    changeTakeTicketDefault(index) {
      const value = this.takeTicketSettingList?.[index]?.value;
      this.currentTakeTicketSetting = value;
    },
    // 监听快捷键
    windowKeyDown(e) {
      if (!this.shortcutKey) return; // 未开启快捷键
      if (this.$store.getters["system/getSYSTEM_INPUT_IS_DEFAULT_DOWN"]) return; // 输入框获取焦点快捷键要失效
      // 键盘按下，处理相关事件
      let ev = window.event || e;
      let code = ev.keyCode || ev.which;
      if (ev.preventDefault) {
        ev.preventDefault();
      } else {
        ev.keyCode = 0;
        ev.returnValue = false;
      }
      this.code = this.keyCode[code];

      // 设置快捷键
      if (this.visibleFastKey) {
        if (this.getSettings.TicketOrderFastKey[this.fastKeyI] === code) return;
        if (
          this.getSettings.TicketOrderFastKey.includes(code) ||
          !this.keyCode[code]
        ) {
          this.$message.warning("该快捷键已被占用，请更换其它快捷键");
        } else {
          this.currentKey = this.keyCode[code];
          this.currentCode = code;
        }
      }
      // 快捷购买
      else {
        let buyI = this.getSettings.TicketOrderFastKey.findIndex(
          item => item === code
        );
        // 购买产品
        if (buyI !== -1) {
          const item = this.list[buyI];
          if (item) {
            this.$refs.selectSeat.loadData(item);
          }
        }
      }
    },
    // 设置快捷键
    setFastKey(i) {
      this.fastKeyI = i;
      this.currentKey = this.keyCode[this.getSettings.TicketOrderFastKey[i]];
      this.currentCode = this.getSettings.TicketOrderFastKey[i];
      this.visibleFastKey = true;
    },
    // 设置快捷键确定
    fastKeyOk() {
      let currentTopMenuKey = this.currentTopMenuKey;
      this.getSettings.TicketOrderFastKey[this.fastKeyI] = this.currentCode;
      this.fastKeyCancel();
      // 存缓存
      localStorage.setItem(
        `${currentTopMenuKey + "TicketOrderFastKey"}`,
        JSON.stringify(this.getSettings.TicketOrderFastKey)
      );
      this.$store.commit(
        `setting/set${currentTopMenuKey + "TicketOrderFastKey"}`,
        this.getSettings.TicketOrderFastKey
      );
    },
    fastKeyCancel() {
      this.visibleFastKey = false;
    }
  }
};
</script>

<style scoped lang="less">
//.门票列表
.ticket-box {
  overflow-y: auto;
  /*height: 300px;*/
  margin: 12px 0;
  padding: 0 16px 16px;
  background: #fff;
  /*&.less {*/
  /*  height: 154px;*/
  /*}*/
  .has-shortcut-key {
    margin-top: 38px !important;
  }
  .ticket-list {
    .ticket-list-item {
      box-sizing: border-box;
      position: relative;
      margin-right: 12px;
      margin-top: 16px;
      width: 100%;
      /*height: 92px;*/
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.23);
      }
      .img {
        font-size: 20px;
        background: #f5f9fc;
        width: 92px;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      }
      .tag {
        padding: 2px 0;
        font-size: 12px;
        color: #707070;
      }
      .right-content {
        height: 100%;
        line-height: 18px;
        padding: 12px;
      }
      .price {
        font-size: 20px;
      }
      .fast-key {
        position: absolute;
        top: -23px;
        right: 9px;
        font-size: 12px;
        .ext {
          padding: 0 4px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          border-radius: 6px 6px 0 0;
          color: #fff;
          cursor: pointer;
        }
        .del {
          .ext;
          min-width: 60px;
          display: flex;
          justify-content: center;
          background: #fff;
          color: #25a2f2;
          border: 1px solid #25a2f2;
          border-bottom: none;
          & > i {
            font-size: 12px;
          }
        }
        .buy {
          .ext;
          min-width: 70px;
          background: #25a2f2;
        }
      }
    }
  }
}
.modal {
  .separate {
    border-top: 1px solid #e8e8e8;
    display: block;
  }
  .modal-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 18px 24px;

    .modal-item-title {
      color: #3566d9;
      font-size: 16px;
    }

    .margin-left {
      margin-left: 16px;
    }

    .modal-item-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
