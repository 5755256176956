<template>
  <div>
    <p class="shop-title">购物信息</p>
    <!--v-if="formData.cartList.length"-->
    <!--门票头部-->
    <div
      class="bd-t p-1x"
      v-for="(item, index) in formData.cartList"
      :key="index"
    >
      <div>
        <div class="flex-between">
          <p>
            <i class="iconfont iconjiatianjiakuangxuanduoxuan-8"></i>
            <span class="pl-1x">边缘行者 3D 国语</span>
          </p>
          <i @click="delItem(item, 0)" class="iconfont icondelete pointer"></i>
        </div>
        <div class="cum-1">
          <div class="font-s flex-start">
            <p class="text-1">
              <span>长白山影院3厅</span>
              <span>5排3座（vip座）</span>
              <span>5排4座（普通座）</span>
            </p>
            <p class="primary pointer flex-shrink ml-1x mr-3x">更多></p>
          </div>
          <div class="flex-between align-center mt-x">
            <span class="font-xl">￥2052</span>
            <p class="font-s">开场时间： 2022-10-12 14:00</p>
            <p class="primary pointer font-s">重新选座 ></p>
          </div>
        </div>
      </div>
      <!--游客-->
      <div class="cum-2">
        <tourist-info
          ref="touristInfo"
          :is-sub-product="false"
          :item="item"
          :index="index"
          @spreadClick="spreadClick"
          @changePlayer="changePlayer"
          @selectAdd="selectAdd"
          @checkAddBuy="checkAddBuy"
          @minusAddBuy="minusAddBuy"
          @addInputBlur="addInputBlur"
          @plusAddBuy="plusAddBuy"
          @delAddBuy="delAddBuy"
          @fastChange="fastChange"
        ></tourist-info>
      </div>
      <div class="p-1x flex-between">
        <p class="bold font-l">
          小计：￥ 234
        </p>
        <div
          class="flex-start align-center"
          v-if="
            item.fast !== 1 && !item.whetherRecommend && !item.requiredVoucherNo
          "
        >
          <!--<a-icon type="minus-circle" class="minus" />-->
          <!--有加购、快速通行、卡腕带押金则禁用修改数量-->
          <span class="bold font-l">x {{ item.buyNum }}</span>
        </div>
      </div>
    </div>
    <a-empty
      class="pb-4x"
      v-if="!formData.cartList.length"
      :image="simpleImage"
    />
    <!--选择加购产品-->
    <product-add-buy
      ref="productAddBuy"
      :params="params"
      :visible.sync="visible"
      @change="updateAddBuyBatch"
    ></product-add-buy>
  </div>
</template>

<script>
import TouristInfo from "./TouristInfo";
import ProductAddBuy from "./ProductAddBuy";
import {
  combinationVisitiorBatch,
  commonAddBuyBatch,
  getTicketAddBuy,
  selectDiscountSku,
  ticketCommonlyVisitiorBatch
} from "../../../../api/cart";
import { faceAnalyzeUpload, face } from "@/api/iot";
import { integerReg } from "../../../../utils/global";
import { fileUploadBase64 } from "../../../../api/upload";
import { Empty } from "ant-design-vue";
export default {
  name: "ProductPlayer",
  props: {
    formatType: {
      // 业态：16会员，6票务，7游乐，8剧院，9酒店，10餐饮，11零售，12租赁，13组合
      type: [Number, String],
      default: ""
    },
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "", // 子景区id
          playDate: "", // 游玩日期
          appointmentRules: "", // 分时预约id
          timeId: "", // 时段id
          name: "", // 产品名称
          categoryId: "" // 分类id
        };
      }
    }
  },
  components: {
    TouristInfo,
    ProductAddBuy
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      // 加购
      cartId: 0,
      formData: {
        cartList: []
      },
      // 加购
      visible: false,
      tempRow: {}, // 临时加购行数据
      tempMainItem: {}, // 临时的组合产品行
      showDiscountSku: false, // 惠民列表弹框
      discountSku: {}, // 惠民列表选中值
      discountSkus: [], //特惠产品列表
      changePlayerItem: {}, // 修改游客信息item纪录
      isReadCard: false, // 是否是读取身份证
      currentCardId: "", // 当前刷的身份证号
      idCardFocusValue: "", // 记录当前聚焦的身份证是否有值
      currentTicketTraveller: {}, // 当前产品信息
      isFocus: false // 是否开启购物车信息自动读取身份证
    };
  },
  methods: {
    // 删除购物车数量
    delItem(item, val) {
      const obj = {
        productId: item.productId,
        index: item.index,
        detailBuyNum: val
      };
      this.currentCardId = ""; // 凡是有删除，清空当前身份证号
      this.$emit("update-cart", obj);
    },
    // // 修改数量
    // changeNum(e, item) {
    //   let val = e.target.value;
    //   if (val) {
    //     const obj = {
    //       productId: item.productId,
    //       index: item.index,
    //       playDate: this.params.playDate,
    //       timeShareAppointmentRuleId: this.params.appointmentRules,
    //       timeIntervalId: this.params.timeId,
    //       subScenicId: this.params.subScenicId,
    //       detailBuyNum: val
    //     };
    //     this.$emit("update-cart", obj);
    //   }
    // },
    /**
     * @params item Object  主产品行
     * @params key String  校验的key
     * @params val Object  动态表单行
     * @params temp Object  子产品行
     * */
    changePlayer(item, val = {}, temp) {
      if (this.isReadCard) return;
      this.discountSku = {};
      this.changePlayerItem = item;
      // 如果是身份证情况下，且已选择时段，则进行惠民弹框
      if (
        val.fieldType === 6 &&
        val.fieldValue &&
        val.fieldValue.trim() &&
        item.appointmentTimePO &&
        item.appointmentTimePO.appointmentBeginTime
      ) {
        let obj = {
          playDate: this.params.playDate, // 游玩日期
          idCard: val.fieldValue, // 身份证号
          timeIntervalId: item.timeIntervalId, // 时段id
          timeShareAppointmentRuleId: this.params.appointmentRules, // 分时预约id
          //   // 多规格 item.specCombination === 1, 否则单规格
          productId: item.productId, // 产品id
          spuId: item.spuId, // 产品spuld
          subScenicId: this.params.subScenicId //子景区id 特惠产品要用到
        };
        selectDiscountSku(obj).then(res => {
          if (res.selectProduct && res.selectProduct.length > 1) {
            // 惠民列表产品大于1，提供选择弹框
            //选择特惠产品
            this.discountSkus = res.selectProduct;
            this.discountSku = res.selectProduct[0];
            this.showDiscountSku = true;
          } else if (res.selectProduct && res.selectProduct.length === 1) {
            // 惠民列表为1不弹框选中
            this.discountSku = res.selectProduct[0];
            this.getTicketTraveller(item, temp);
          } else {
            // 是身份证情况下，没惠民政策
            this.getTicketTraveller(item, temp);
          }
        });
      } else {
        // 非填写身份证情况下
        if (val.fieldType !== 6) {
          this.getTicketTraveller(item, temp);
        } else if (
          (val.fieldType === 6 && val.fieldValue) ||
          this.idCardFocusValue
        ) {
          // 如果当前为身份证，并且有值的情况下失去焦点调用
          this.getTicketTraveller(item, temp);
        }
      }
    },
    idCardFocus(val) {
      this.isFocus = true;
      this.idCardFocusValue = val;
    },
    faceChange(item, obj) {
      this.getTicketTraveller(item, obj);
    },
    // 更新游客信息
    getTicketTraveller(item, temp) {
      this.currentTicketTraveller = item || temp;
      // 把凭证信息复制到更多游客信息
      let obj = {
        cartId: this.cartId,
        productId: item.productId,
        index: item.index,
        traveler: this.getTraveler(temp || item) // temp 为组合子产品行，item为主产品行
      };
      // 惠民政策
      if (this.discountSku.selectProductSkuId) {
        obj.discountProductId = this.discountSku.selectProductSkuId;
      }
      // 如果是组合产品 一子产品一游客时
      if (temp) {
        obj.subProductId = temp.productId;
        obj.subIndex = temp.index;
      }
      this.$emit("update-player", obj, this.callback);
    },
    callback() {
      this.currentTicketTraveller.IdTypeList.forEach(item => {
        // 更新购物车报错时清空身份证value
        if (item.fieldType === 6) item.fieldValue = "";
      });
    },
    // 获取游客信息
    getTraveler(item) {
      const obj = {
        visitorFieldList: item.visitorFieldList.map(temp => {
          return {
            id: temp.id,
            fieldType: temp.fieldType,
            fieldValue: temp.fieldValue
          };
        })
      };
      // 如果存在证件类型列表
      if (item.IdTypeList.length) {
        const arr = [];
        item.IdTypeList.forEach(temp => {
          // 如果凭证名称或证件类型选择了指定证件，则录入证件后到购物车，否则不录入
          if (temp.fieldType === item.IdType) {
            // 如果是身份证类型，又有性别时自动给选着性别
            let sexIndex = item.visitorFieldList.findIndex(
              te => te.fieldType === 8
            );
            if (item.IdType === 6 && temp.fieldValue && sexIndex !== -1) {
              // 1输入身份证可以自动写入，2.防止先选了性别，再填身份证，性别和证件的性别不符(身份证倒数第二位单数为男)
              let sexType = ["1", "3", "5", "7", "9"].includes(
                temp.fieldValue.slice(-2, -1)
              );
              obj.visitorFieldList[sexIndex].fieldValue = sexType ? "男" : "女";
            }
            arr.push({
              id: temp.id,
              fieldType: temp.fieldType,
              fieldValue: temp.fieldValue
            });
          } else {
            arr.push({
              id: temp.id,
              fieldType: temp.fieldType,
              fieldValue: ""
            });
          }
        });
        obj.visitorFieldList = obj.visitorFieldList.concat(arr);
      }
      // 游客类型
      if (item.visitorTypeId) obj.visitorTypeId = item.visitorTypeId;
      // 添加卡腕带号
      if (item.requiredVoucherNo) {
        // 为0不开启卡腕带录入
        obj.voucherNo = item.voucherNo;
      }
      // 添加人脸
      if (item.requiredPersonId) {
        // 为1不开启人脸录入
        obj.personId = item.personId;
        obj.personFaceUrl = item.personFaceUrl;
      }
      return obj;
    },
    // 快速通行
    fastChange(item, obj) {
      const data = {
        cartId: this.cartId,
        productId: item.productId,
        index: item.index
      };
      // 一子产品一游客
      if (obj) {
        data.subProductId = obj.productId;
        data.subIndex = obj.index;
        data.whetherFastTicket = obj.whetherFastTicket ? 1 : 0;
      }
      // 一种产品一游客、、票务
      else {
        data.whetherFastTicket = item.whetherFastTicket ? 1 : 0;
      }
      this.$emit("update-fast", data);
    },
    // 选择加购 mainItem: 如果是组合产品一子产品一游客则存在mainItem
    selectAdd(row, mainItem) {
      getTicketAddBuy({
        productId:
          mainItem.combUseRule === 2 ? mainItem.productId : row.productId,
        dailyCalendar: this.params.playDate
      })
        .then(res => {
          this.$refs.productAddBuy.loadData(res, row.subProductList);
          this.tempRow = row;
          if (mainItem.combUseRule === 2) {
            this.tempMainItem = mainItem;
          }
        })
        .catch(() => {});
    },
    // 选中加购
    checkAddBuy(item, temp, obj) {
      const val = temp.productFlagPO.whetherCheck ? 0 : 1;
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          whetherCheck: val
        },
        obj
      );
    },
    // 加购加
    plusAddBuy(item, temp, obj) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          addBuyNum: temp.productFlagPO.buyNum + 1
        },
        obj
      );
    },
    // 加购减
    minusAddBuy(item, temp, obj) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      if (temp.productFlagPO.buyNum > 0)
        this.updateAddBuy(
          {
            productId: item.productId,
            index: item.index,
            addBuyProductId: temp.productId,
            addBuyNum: temp.productFlagPO.buyNum - 1
          },
          obj
        );
    },
    // 删除加购
    delAddBuy(item, temp, obj) {
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          addBuyNum: 0
        },
        obj
      );
    },
    // 更新加购产品
    addInputBlur(e, item, temp, obj) {
      const val = e.target.value;
      if (integerReg.test(val)) {
        this.updateAddBuy(
          {
            productId: item.productId,
            index: item.index,
            addBuyProductId: temp.productId,
            addBuyNum: val
          },
          obj
        );
      }
    },
    // 修改弹窗加购产品数量
    updateAddBuyBatch(data) {
      let obj = {};
      // combUseRule === 2 组合产品：一子产品一游客需要subIndex和subProductId
      if (this.tempMainItem.combUseRule === 2) {
        obj = {
          productId: this.tempMainItem.productId,
          index: this.tempMainItem.index,
          subProductId: this.tempRow.productId,
          subIndex: this.tempRow.index,
          ...data
        };
      } else {
        obj = {
          productId: this.tempRow.productId,
          index: this.tempRow.index,
          ...data
        };
      }
      obj.cartId = this.cartId;
      commonAddBuyBatch(obj)
        .then(res => {
          this.$emit("change-cart", res);
        })
        .catch(() => {});
    },

    // 更新加购
    updateAddBuy(data, obj) {
      // 如果是组合产品：一子产品一游客
      if (obj) {
        data.subProductId = obj.productId;
        data.subIndex = obj.index;
      }
      this.$emit("update-add-buy", data);
    },
    // 刷身份证自动匹配产品到购物车
    changeCard(info) {
      this.isFocus = false;
      let obj = {
        formatType: this.formatType,
        playDate: this.params.playDate, // 游玩日期
        subScenicId: this.params.subScenicId, // 子景区id
        timeIntervalId: this.params.timeId,
        cartId: this.cartId || "",
        travelers: [
          {
            visitorFieldList: [
              {
                id: "1380460304389713921",
                fieldType: 6,
                fieldValue: info.idCard
              }
            ]
          }
        ]
      };
      // 票务和游乐用同一个接口
      let api = ticketCommonlyVisitiorBatch;
      let type = this.formatType;
      if (type == 13) {
        // 组合
        api = combinationVisitiorBatch;
      }
      api(obj).then(res => {
        this.$emit("change-cart", res);
      });
    },
    // 购物车改动后更新
    updateCart({ detailProductList, id }) {
      this.cartId = id;
      // 处理购物车返回的数据
      const dealData = item => {
        // 分时预约按子景区走则使用头部筛选条件的，否则通过接口获取时段列表
        // const arr = this.appointment !== 1 ? this.times : [];
        // 过滤出可用时段(库存大于0 且 时段大于当前选中日期)
        // const times = arr.filter(temp => {
        //   return (
        //     temp.remainingStock > 0 &&
        //     (this.params.playDate < temp.endTime
        //       ? temp.endTime
        //       : this.params.playDate > new Date().getTime())
        //   );
        // });
        // 处理出游人信息字段，把身份证、护照、港澳通行证、台胞证统一合并为证件类型，只能填写一个
        const visitorFieldList1 = [];
        const visitorFieldList2 = [];
        let IdType = "";
        // 防止后台不返回traveler字段报错
        item.traveler = item.traveler || {
          visitorFieldList: [],
          memberTouristList: []
        };
        item.traveler.visitorFieldList.forEach(temp => {
          if (
            temp.fieldType === 6 ||
            temp.fieldType === 17 ||
            temp.fieldType === 18 ||
            temp.fieldType === 19 ||
            temp.fieldType === 21
          ) {
            visitorFieldList1.push(temp);
            if (temp.fieldValue) {
              IdType = temp.fieldType;
            }
          } else {
            visitorFieldList2.push(temp);
          }
        });

        return {
          index: item.index, // 序号
          productId: item.productId,
          spuId: item.spuId,
          productName: item.productName, // 产品名称
          whetherSpecialDiscount: item.productFlagPO.whetherSpecialDiscount, // 是否特惠产品
          oldProductName: item.productFlagPO.oldProductName, // 特惠产品前旧产品名称
          ownerMerchantName: item.ownerMerchantName, // 商户名称
          sellingAmount: item.sellingAmount / 100, // 价格
          buyNum: item.productFlagPO.buyNum, // 数量
          buyNumDiasabled: false, // 修改数量禁用
          IdType:
            IdType ||
            (visitorFieldList1[0] ? visitorFieldList1[0].fieldType : ""), // 证件类型
          IdTypeList: visitorFieldList1, // 证件类型列表
          visitorFieldList: visitorFieldList2, // 游客信息
          memberTouristList: item.traveler.memberTouristList, // 游客类型列表
          visitorTypeId: item.traveler.visitorTypeId, // 选中的游客类型
          wristbandPrice: item.traveler.wristbandPrice, // 游客信息
          fast: item.traveler.fast, // 是否开启快票功能
          whetherFastTicket: !!item.traveler.whetherFastTicket, // 是否打开了快票
          timeIntervalId: item.appointmentTimePO
            ? item.appointmentTimePO.timeIntervalId
            : "", // 选中的时段id
          appointmentBeginTime: item.appointmentTimePO?.appointmentBeginTime, // 预约开始时段
          appointmentEndTime: item.appointmentTimePO?.appointmentEndTime, // 预约结束时段
          playerType: "", // 游客类型
          numDisabled: false, // 修改数量禁用
          // 加购产品列表
          subProductList: item.subProductList.map(temp => {
            // 设置时段选择
            this.$set(
              temp,
              "timeIntervalId",
              temp.appointmentTimePO
                ? temp.appointmentTimePO.timeIntervalId
                : ""
            );
            // this.$set(temp, "times", times);
            // this.$set(temp, "timeOpen", false);
            return temp;
          }),
          whetherRecommend: item.whetherRecommend, // 是否有推荐加购
          personFaceUrl: item.traveler.personFaceUrl, // 人脸头像
          personId: item.traveler.personId, // 人脸头像id
          timeShareAppointmentRuleId: item.timeShareAppointmentRuleId, // 产品是否开启分时预约
          // timeOpen: false,
          playDate: item.playDate,
          appointmentTimePO: item.appointmentTimePO,
          visibleTime: false,
          voucherNo: item.traveler.voucherNo || "",
          requiredPersonId: item.traveler.requiredPersonId,
          requiredPersonTrue: item.traveler.requiredPersonTrue,
          requiredVoucherNo: item.traveler.requiredVoucherNo,
          fixValidStartTime: item.fixValidStartTime, // 产品有效期
          fixValidEndTime: item.fixValidEndTime // 产品有效期
        };
      };
      this.formData.cartList = detailProductList.map(item => {
        // 如果使用规则是一子产品一游客，则游客信息放在
        if (item.productFlagPO.combUseRule === 2) {
          return {
            productId: item.productId,
            index: item.index, // 序号
            productName: item.productName, // 组合产品名称
            sellingAmount: item.sellingAmount, // 组合产品价格
            combUseRule: 2, // 一子产品一游客
            whetherRecommend: item.whetherRecommend, // 是否有加购
            timeShareAppointmentRuleId: item.timeShareAppointmentRuleId, // 时段规则id
            // timeOpen: false,
            visibleTime: false,
            // 子产品列表
            subProductList: item.subProductList.map(temp => {
              return dealData(temp);
            })
          };
        } else {
          return dealData(item);
        }
      });
      // 更新弹窗加购产品数量
      // if (this.$refs.productAddBuy && this.tempRow) {
      //   const row = this.formData.cartList.find(
      //     item => item.index === this.tempRow.index
      //   );
      //   if (row) this.$refs.productAddBuy.updateData(row.subProductList);
      // }
      /**
       * 批量轮询身份证自动填入对应信息
       */
      let list = this.formData.cartList;
      // let shoppingSpreadList = sessionStorage.getItem("shoppingSpread");
      // if (shoppingSpreadList) {
      //   let arr = JSON.parse(shoppingSpreadList);
      //   list.forEach(te => {
      //     let val = arr.find(
      //       v => v.index === te.index && v.productId === te.productId
      //     );
      //     if (val) {
      //       te.levelOne = val.levelOne;
      //       te.levelTwo = val.levelTwo;
      //       te.levelThree = val.levelThree;
      //     }
      //   });
      // }
      // 如果清空了购物车，初始化数据
      if (!list.length) {
        this.isReadCard = false; // 记录当前是否是读取身份证调用
        this.currentCardId = ""; // 用于记录当前读取身份号
        this.isFocus = false; // 清空完购物车关闭自动获取焦点
      }
      // 如果开启了自动聚焦身份证
      if (this.isFocus) {
        for (let i = 0; i < list.length; i++) {
          let tem = list[i];
          // 组合（一子产品一游客）
          if (tem.combUseRule === 2) {
            for (let j = 0; j < tem.subProductList.length; j++) {
              let subProductItem = tem.subProductList[j];
              // 查找列表是否有身份证录入
              let subIndex = subProductItem.IdTypeList.findIndex(
                te => te.fieldType === 6
              );
              // 如果找到身份证且没值时，自动聚焦
              if (
                subIndex !== -1 &&
                !subProductItem.IdTypeList[subIndex].fieldValue &&
                subProductItem.IdType == 6
              ) {
                this.$nextTick(() => {
                  this.$refs[`idCardInput${i}${j}`][0].focus();
                });
                return;
              }
            }
          } else {
            // 查找列表是否有身份证录入
            let k = tem.IdTypeList.findIndex(te => te.fieldType === 6);
            // 如果找到身份证且没值时，自动聚焦
            if (k !== -1 && !tem.IdTypeList[k].fieldValue && tem.IdType == 6) {
              this.$nextTick(() => {
                this.$refs[`idCardInput${i}`][0].focus();
              });
              return;
            }
          }
        }
      }
    },
    // 人脸、证件识别涉及的方法区域 （如果是组合产品：一子产品一游客 val为obj）
    readCard(res, val, key, temp, valItem) {
      this.currentCardId = res.id;
      val.visitorFieldList.forEach(item => {
        //填充姓名
        if (item.fieldType == 4) {
          item.fieldValue = res.name;
        } else if (item.fieldType == 7) {
          //生日,分组匹配出年月日，再转换成moment格式
          // let reg = /(\d+)-(\d+)-(\d+)/;
          // let target = res.birth.match(reg);
          // let birthday = moment()
          //   .year(target[1])
          //   .month(Number(target[2]) - 1)
          //   .date(target[3])
          //   .format("YYYY-MM-DD");
          item.fieldValue = res.birth;
        } else if (item.fieldType == 8) {
          //性别(2男，1女)
          if (res.sex === "男") {
            item.fieldValue = "男";
          } else {
            item.fieldValue = "女";
          }
        } else if (item.fieldType == 9) {
          this.isReadCard = true;
          //证件照
          fileUploadBase64({ data: res.photo, ext: "png" })
            .then(value => {
              this.$set(item, "fieldValue", value.src);
              if (val.requiredPersonId) {
                // 如果有人脸
                this.getPicture(res.photo, val, key, temp, valItem);
              } else {
                if (!valItem) {
                  this.isReadCard = false;
                  this.changePlayer(val, key, temp);
                } else {
                  // 一子产品一游客
                  this.isReadCard = false;
                  this.changePlayer(valItem, key, temp, val);
                }
              }
            })
            .catch(() => {
              this.isReadCard = false;
              this.$message.error("图片上传接口vv错误");
            });
        } else if (item.fieldType == 12) {
          // 地址
          item.fieldValue = res.address;
        }
      });
      // if (this.formItemData.requiredPersonId) this.getPicture(res.photo);
    },
    getPicture(data64, val, key, temp, valItem) {
      this.imgbase64 = `data:image/png;base64,${data64}`;
      //this.$message.success({ content: this.imgbase64, duration: 10 });
      faceAnalyzeUpload({ imageBase64: this.imgbase64 })
        .then(res => {
          // this.$message.success(JSON.stringify(res));
          this.saveFace(res.src, val, key, temp, valItem);
        })
        .catch(() => {
          this.isReadCard = false;
        });
    },
    // 保存人脸
    saveFace(url, val, key, temp, valItem) {
      //type：1是会员，0是员工
      let postData = { imageUrl: url, type: 1 };
      face(postData)
        .then(res => {
          val.personFaceUrl = url;
          val.personId = res.personId;
          if (!valItem) {
            this.isReadCard = false;
            this.changePlayer(val, key, temp);
          } else {
            // 一子产品一游客
            this.isReadCard = false;
            this.changePlayer(valItem, key, temp, val);
          }
        })
        .catch(() => {
          this.isReadCard = false;
        });
    },
    spreadClick() {
      // let arr = [];
      // this.formData.cartList.forEach(item => {
      //   arr.push({
      //     index: item.index,
      //     levelOne: item.levelOne,
      //     levelTwo: item.levelTwo,
      //     levelThree: item.levelThree,
      //     productId: item.productId
      //   });
      // });
      // // 缓存当前购物车展开收起各个支点状态
      // sessionStorage.setItem("shoppingSpread", JSON.stringify(arr));
    },
    // 校验购物车表单
    validate() {
      return this.$refs.touristInfo[0].validate();
    }
  }
};
</script>

<style scoped lang="less">
.shop-title {
  font-size: 16px;
  font-weight: bold;
  padding: 12px 16px;
}
.cum-1 {
  padding-left: 24px;
}
.cum-2 {
  padding-left: 24px;
}
.icon-add {
  position: relative;
  left: -7px;
}
/*加减号*/
.minus {
  color: #a4a4a4;
  font-size: 20px;
  cursor: pointer;
}
.number {
  margin: 0 16px;
  line-height: 24px;
  font-weight: 500;
  font-size: 18px;
  color: #25a2f2;
}
/*减号*/
.plus {
  font-size: 20px;
  color: #25a2f2;
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
}
.input-number {
  margin: 0 4px;
  border: none;
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  outline-color: #dcdfe6;
  color: #25a2f2;
  background: #f1f1f1;
}
</style>
