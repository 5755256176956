<template>
  <div>
    <div
      class="flex-between flex-col wrapper-new"
      v-show="!showMember && !showPayOnly"
    >
      <!--筛选条件-->
      <product-filter @change="filterChange"></product-filter>
      <!-- 中间区域-->
      <a-row class="layout" :class="{ 'hide-top': !showMenu }">
        <!--左侧-->
        <a-col :lg="12" :xl="14" :xxl="15" class="content-left">
          <!--影视幻灯区域-->
          <product-carousel
            ref="productCarousel"
            @change="carouselChange"
          ></product-carousel>
          <!--购买日期-->
          <product-date ref="productDate" @change="dateChange"></product-date>
          <!--产品列表区域-->
          <product-list
            :sub-scenic-id="params.subScenicId"
            @update-cart="updateCart"
            ref="productList"
          ></product-list>
        </a-col>
        <!--右侧-->
        <a-col :lg="12" :xl="10" :xxl="9" class="content-right">
          <!--右侧购物信息区域-->
          <product-player
            ref="productPlayer"
            @update-cart="updateCart"
            @change-cart="changeCart"
            @update-add-buy="changeAddBuy"
            @update-player="updatePlayer"
          ></product-player>
        </a-col>
      </a-row>
      <!--底部footer-->
      <product-calc-footer
        @pay-only="payOnly"
        @member="showMember = true"
      ></product-calc-footer>
    </div>
    <!-- 会员详情 -->
    <member-detail
      class="flex-grow"
      v-if="showMember"
      format="lease"
      @close="showMember = false"
    ></member-detail>
    <!--直接付款-->
    <pay-only
      :visible.sync="showPayOnly"
      ref="payOnly"
      @success="successPayOnly"
    ></pay-only>
  </div>
</template>

<script>
import ProductFilter from "./components/ProductFilter";
import ProductList from "./components/ProductList";
import ProductPlayer from "./components/ProductPlayer";
import ProductCalcFooter from "./components/ProductCalcFooter";
import MemberDetail from "../../home/member/MemberDetail.vue";
import PayOnly from "../../../components/sell/commonents/PayOnly";
import moment from "moment";
import { updateQuickOrderWriteOffStatus } from "../../../api/order";
import {
  cartTicket,
  postTicketAddBuy,
  ticketMore,
  ticketTraveller
} from "../../../api/cart";
import ProductCarousel from "./components/ProductCarousel";
import ProductDate from "./components/ProductDate";
export default {
  name: "SellProduct",
  components: {
    ProductFilter,
    ProductCarousel,
    ProductDate,
    ProductList,
    ProductPlayer,
    ProductCalcFooter,
    MemberDetail,
    PayOnly
  },
  data() {
    return {
      moment,
      params: {
        subScenicId: "", // 子景区id
        sessionDate: "", // 开始日期
        theaterId: "" // 剧目id
      },
      offsetHeight: 0,
      showMember: false, // 会员详情
      showPayOnly: false, // 直接付款
      cart: {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [] // 需要出游人的产品
      }
    };
  },

  computed: {
    // 是否展示菜单
    showMenu() {
      return this.$store.state.common.showMenu;
    }
  },

  methods: {
    // 头部查询 获取数据
    filterChange(obj) {
      this.params.subScenicId = obj.subScenicId;
      this.params.theaterId = "";
      this.params.sessionDate = "";
      this.$refs.productCarousel.loadData(obj.subScenicId);
      this.$refs.productDate.loadData();
    },
    // 剧目改变
    carouselChange(theaterId) {
      this.params.theaterId = theaterId;
      if (this.params.sessionDate) {
        this.bannerChange();
      }
    },
    // 日期改变
    dateChange(sessionDate) {
      this.params.sessionDate = sessionDate;
      if (this.params.theaterId) {
        this.bannerChange();
      }
    },
    // 最终改变
    bannerChange() {
      this.$refs.productList.loadData(this.params);
    },
    // 添加/更新到购物车 - 调接口
    async updateCart(obj) {
      // 添加购物车
      let api = cartTicket;
      // 更新购物车
      if (this.cart.id) {
        obj.cartId = this.cart.id;
        api = ticketMore;
      }
      // 添加会员信息
      else {
        // // 如果已经有会员信息
        // if (this.memberInfo.id) {
        //   obj.purchaserMemberId = this.memberInfo.id;
        // }
        // 如果本地存有默认打印方式
        // if (this.getSettings.PrintType) {
        //   obj.cartPrintType = this.getSettings.PrintType;
        // }
      }

      let ok = true;
      await api(obj)
        .then(res => {
          if (res) {
            this.cart = res;
          }
          // 清空购物车
          else {
            this.clearCart();
          }
        })
        .catch(() => {
          ok = false;
        })
        .finally(() => {
          // 更新产品列表数量
          this.broadcast();
        });
      return ok;
    },
    // 清空购物车
    clearCart() {
      this.cart = {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [] // 需要出游人的产品
      };
      this.broadcast();
    },
    // 已存在购物车，更新到购物车 - 不调接口
    changeCart(cart) {
      this.cart = cart;
      this.broadcast();
    },
    // 通知子组件购物车更新
    broadcast() {
      this.$refs.productList.updateNum(this.cart);
      this.$refs.productPlayer.updateCart(this.cart);
      // this.$refs.productCalcFooter.updateCart(this.cart);
    },
    // 加购更新
    changeAddBuy(obj) {
      postTicketAddBuy({
        cartId: this.cart.id,
        ...obj
      })
        .then(res => {
          this.cart = res;
          this.broadcast();
        })
        .catch(() => {});
    },
    // 更新游客信息
    updatePlayer(data, callback) {
      ticketTraveller(data)
        .then(res => {
          this.changeCart(res);
        })
        .catch(() => {
          callback();
        });
    },
    // 校验购物车表单
    validate() {
      return this.$refs.productPlayer.validate();
    },
    // 支付成功
    successPayOnly() {
      updateQuickOrderWriteOffStatus({
        id: this.payParams.id,
        writeOffStatus: 3,
        updateTime: moment().valueOf()
      })
        .then(() => {})
        .catch();
    },
    payOnly(val) {
      this.payParams = val;
      this.$nextTick(() => {
        this.$refs.payOnly.changeData(val);
        this.showPayOnly = true;
      });
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../assets/less/custom";
@import "../../../components/sell/ticket-order-common";
.wrapper-new {
  height: calc(100vh - 64px);
  .layout {
    flex-grow: 1;
    margin-top: 12px;
    padding-left: 12px;
    .content-left,
    .content-right {
      height: calc(100vh - 294px);
      overflow-y: auto;
      background: #fff;
    }
    .content-right {
      border-left: 12px solid #f5f5f5;
    }
  }
  /*头部菜单隐藏*/
  .hide-top {
    .content-left,
    .content-right {
      height: calc(100vh - 230px);
    }
  }
}
</style>
