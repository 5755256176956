<!--剧场购买日期筛选-->
<template>
  <div class="product-date">
    <ul class="flex-around align-center flex-grow pr-2x">
      <li
        class="date"
        :class="{ active: item === currentDate }"
        v-for="item in dates"
        :key="item"
        @click="clickDate(item)"
      >
        <p class="font-xl">{{ weekStr[moment(item).format("d")] }}</p>
        <p class="font-s mt-x">{{ moment(item).format("M月D日") }}</p>
      </li>
    </ul>
    <a-date-picker
      v-model="currentMoment"
      :allow-clear="false"
      :disabled-date="disabledDate"
      @change="datePickerChange"
    />
  </div>
</template>

<script>
import moment from "moment";
import { merchantSettingOrderInfo } from "../../../../api/merchant";

export default {
  name: "ProductDate",

  data() {
    return {
      moment,
      /** 分时预约选择 1.产品  2.子景区 3.景区 */
      appointmentSelection: 1,
      /** 景区、子景区维度分时预约规则  */
      scenicDpotReservation: [],
      /**下单规则 */
      dates: [], // 日期列表
      startDate: "", // 可售开始日期
      endDate: "", // 可售结束日期
      currentMoment: moment(), // 当前时间moment
      times: [], // 时段
      appointmentRules: "", // 分时预约规则id
      timeId: "", // 时段Id

      weekStr: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],

      showCalendar: false,
      timer: null // 定时器
    };
  },

  computed: {
    // 当前选中时间
    currentDate() {
      return this.currentMoment.valueOf();
    }
  },

  methods: {
    // 初始化 - 获取景区设置
    async loadData() {
      // 最新业态：16会员，6票务，7游乐，8剧院，9酒店，10餐饮，11零售，12租赁，13组合
      const setting = await merchantSettingOrderInfo({
        formatType: 8
      });
      // console.log(setting);
      if (setting.orderRules.length === 0) {
        this.$error({
          title: "提示",
          content:
            "该景区未配置下单规则，请到后台管理系统的“景区设置”配置或联系管理员！"
        });
        return false;
      }
      // 处理可选日期
      this.loadDate(setting);
      this.change();
      return true;
    },
    // 加载日期 1不限 2 T+N后N天 3：T+N
    loadDate({ orderRules }) {
      if (orderRules.length) {
        const obj = orderRules[0];
        if (obj.orderDateRange == 1) {
          this.startDate = moment()
            .startOf("day")
            .valueOf();
          this.endDate = "";
        } else if (obj.orderDateRange == 2) {
          // 计算可选日期范围
          let t = Number(obj.ttoDay);
          let dates = [];
          for (let i = 0; i <= t; i++) {
            let day = moment()
              .startOf("day")
              .add(+Number(i + obj.tafterDay), "day")
              .valueOf();
            dates.push(day);
          }
          this.startDate = dates[0];
          this.endDate = dates[dates.length - 1];
        } else if (obj.orderDateRange == 3) {
          this.startDate = moment()
            .startOf("day")
            .add(+Number(obj.tafterDay), "day")
            .valueOf();
          this.endDate = "";
        }
      } else {
        this.startDate = moment()
          .startOf("day")
          .valueOf();
        this.endDate = "";
      }
      this.currentMoment = moment(this.startDate);
      // 生成快捷日期列表
      this.createDate();
    },
    // 生成快捷日期列表
    createDate() {
      let count = 0;
      // 一天为86400000毫秒
      let endDate = this.endDate || this.currentDate + 86400000 * 6;
      const dates = [this.currentDate];
      while (count < 6 && dates[count] < endDate) {
        count++;
        dates.push(this.currentDate + 86400000 * count);
      }
      // 如果不足7天，且往前推还有时间
      while (count < 6 && dates[0] > this.startDate) {
        count++;
        dates.unshift(dates[0] - 86400000);
      }

      this.dates = dates;
      // console.log(dates);
    },
    // 禁用日期可选范围
    disabledDate(currentDate) {
      return (
        currentDate.valueOf() < this.startDate ||
        (this.endDate ? currentDate.valueOf() > this.endDate : false)
      );
    },
    // 点击快捷日期
    clickDate(date) {
      this.currentMoment = moment(date);
      this.change();
    },
    // 日期选择
    datePickerChange(val) {
      this.currentMoment = val;
      this.createDate();
    },
    // 触发日期修改事件
    change() {
      this.$emit("change", this.currentDate);
    }
  }
};
</script>

<style scoped lang="less">
.product-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
  padding: 0 16px;
  border-bottom: 1px solid #e6e6e6;

  .date {
    padding: 0 4px;
    text-align: center;
    color: #707070;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: #333;
    }
    &.active {
      color: #376edc;
    }
  }
}
</style>
