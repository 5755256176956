<template>
  <div class="section filter">
    <div class="flex-between">
      <div class="flex-grow flex-start align-center">
        <!-- 景区选择 -->
        <a-select
          placeholder="请选择"
          class="scenic-select"
          v-model="subScenicId"
          @change="scenicChange"
        >
          <a-select-option
            :value="item.subScenicId"
            v-for="item in subScenics"
            :key="item.subScenicId"
            >{{ item.subScenicName }}</a-select-option
          >
        </a-select>
        <a-input
          v-model="managementName"
          class="management-name"
          placeholder="请选择或填写团体名称"
          allow-clear
        >
          <span style="cursor: pointer;" slot="addonAfter">选择</span>
        </a-input>

        <a-select
          show-search
          v-inputfocus
          placeholder="请选择团体分类"
          class="scenic-select"
          @change="changeTourist"
        >
          <a-select-option
            v-for="item in [{ id: 1, touristCategoryName: '美团类型' }]"
            :key="item.id"
            :value="item.id"
            >{{ item.touristCategoryName }}</a-select-option
          >
        </a-select>
        <a-select
          show-search
          v-inputfocus
          class="scenic-select last"
          v-model="guideProductId"
          placeholder="请选择导游票"
          @change="changeGuideProductId"
          :allowClear="true"
        >
          <a-select-option
            v-for="item in [{ id: 1, name: '张三导游票' }]"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
      <!-- 产品查询 -->
      <a-input-search
        v-inputfocus
        allow-clear
        class="search-input"
        placeholder="请输入产品名称"
        v-model="searchKey"
        @search="onSearch"
      >
        <a-button slot="enterButton" icon="search" type="primary" ghost>
          搜索
        </a-button>
      </a-input-search>
    </div>
  </div>
</template>

<script>
import { subScenics as getSubScenic } from "../../../../api/merchant";
export default {
  name: "ProductFilter",
  created() {
    this.loadScenics().then(() => {
      this.change();
    });
  },
  data() {
    return {
      subScenics: [], // 业态下拉框
      subScenicId: "", // 默认选中的 业态id
      searchKey: "", // 搜索的key
      managementName: "", // 团体名称
      managementId: "", // 选中旅行社id
      guideProductId: undefined // 选中子景区产品id
    };
  },
  methods: {
    // 获取 业态列表
    async loadScenics() {
      const { subScenics } = await getSubScenic({ filterSubScenic: true });
      this.subScenics = subScenics;
      this.subScenicId = subScenics[0].subScenicId;
    },
    // 改变业态触发
    scenicChange(value) {
      this.subScenicId = value;
      this.change();
    },
    // 选择团体分类
    changeTourist() {},
    // 选择导游票
    changeGuideProductId() {},
    // 搜索
    onSearch() {
      this.change();
    },
    // 筛选条件改变
    change() {
      const obj = {
        subScenicId: this.subScenicId,
        name: this.searchKey
      };
      this.$emit("change", obj);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../assets/less/custom";
/*筛选表单*/
.filter {
  .scenic-select {
    width: 150px;
    margin-right: 24px;
  }
  .management-name {
    width: 15rem;
    margin-right: 24px;
  }
  .line {
    margin-right: 24px;
    height: 24px;
    border-right: 1px solid #d1d1d1;
  }
  .appointment-time {
    width: 305px;
    height: 30px;
    margin-right: 24px;
    padding: 0 12px;
    line-height: 30px;
    align-self: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    user-select: none;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
    &:hover {
      cursor: pointer;
      border-color: @primary;
    }
  }
  .search-input {
    width: 389px;
  }

  .select-date {
    padding: 16px 16px 0;
    margin-left: 186px;
    .date-item {
      margin-left: 18px;
      width: 130px;
      height: 36px;
      line-height: 34px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #b8b8b8;
      border-radius: 30px;
      color: #333;
      font-weight: bold;
      cursor: pointer;
    }

    .time-item {
      margin-left: 16px;
      margin-top: 16px;
      width: 130px;
      height: 50px;
      border: 1px solid #b8b8b8;
      border-radius: 14px;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
  .scenic-select {
    width: 150px;
    // margin-right: 24px;
  }
}
</style>
