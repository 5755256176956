<!-- 售剧场票页-->
<template>
  <div class="flex-between flex-grow ">
    <!--左边-场次-->
    <div
      v-show="!showPurchase && !showMember"
      class="wrapper flex-between flex-grow flex-col mr-2x"
    >
      <header class="header">
        <!-- 剧目搜索 -->
        <div>
          <span class="mr-1x text-v">剧目名称</span>
          <a-input-search
            style="width: 215px"
            class="text-v"
            v-model="name"
            placeholder="请输入剧目名称"
            @search="onSearch"
            v-inputfocus
            enter-button
            allowClear
          />
        </div>
        <span class="ml-3x mr-1x">场地</span>
        <a-select
          @change="fieldChange"
          class="field"
          v-model="field"
          mode="multiple"
          :maxTagCount="2"
          :maxTagTextLength="4"
          allowClear
          placeholder="请选择场地"
          notFoundContent="暂无数据"
        >
          <a-select-option
            v-for="item in fieldList"
            :key="item.id"
            :value="item.spuId"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </header>
      <section class="main container flex-grow mt-2x">
        <!-- 剧目选择 -->
        <div class="flex-center align-center repertoire">
          <ul class="flex-start repertoire-list" v-if="repertoireList.length">
            <li
              v-for="item in repertoireList"
              :key="item.id"
              @click="onRepertoireChoice(item)"
              :class="{ active: defaultChecked == item.id }"
              class="text-c pointer mr-2x text-1"
            >
              <img :src="item.skuImages" :alt="item.name" />
              <a-tooltip
                placement="bottom"
                arrowPointAtCenter
                :title="item.name"
              >
                <p class="mt-1x">{{ item.name }}</p>
              </a-tooltip>
            </li>
          </ul>

          <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" />
        </div>
        <!-- 日期选择 -->
        <ul class="flex-between align-center font-l mt-2x">
          <li
            v-for="(item, i) in onDates"
            :key="i"
            @click="onDateClick(item, i)"
            class="pointer ph-1x"
            :class="i == dateIndex ? ['bd-b', 'bd-primary', 'primary'] : ''"
          >
            {{ item }}
          </li>
          <li
            :class="specifyDate ? 'primary' : 'gray'"
            class="p-1x text-c pointer font-l"
            @click="calendarClick"
          >
            查看日历
            <div class="font-l">{{ specifyDate }}</div>
          </li>
        </ul>
        <!-- 日历组件 -->
        <a-modal v-model="showCalendar" title="查看日历" centered>
          <div class="bd round">
            <a-calendar
              v-model="calendar"
              :fullscreen="false"
              valueFormat="YYYY-MM-DD"
            />
          </div>
          <div slot="footer" class="text-c">
            <a-button @click="handleCancel">取消</a-button>
            <a-button type="primary" @click="handleOk">确定</a-button>
          </div>
        </a-modal>
        <!-- 表格开始 -->
        <div class="table mt-2x">
          <a-table
            bordered
            :pagination="false"
            :data-source="dataSource"
            :columns="columns"
            :scroll="{ x: 700, y: 350 }"
          >
            <template slot="priceRange" slot-scope="text, record">
              <span class="price"> ¥ {{ record.priceRange }} </span>
            </template>
            <template slot="time" slot-scope="text, record">
              <span>{{ record.beginTime }} - {{ record.endTime }}</span>
            </template>
            <template slot="duration" slot-scope="text, record">
              <span>{{ record.duration }}分钟</span>
            </template>
            <template slot="operation" slot-scope="text, record">
              <a-button
                type="primary"
                :disabled="!record.chooseSeat"
                ghost
                @click="onSelection(record)"
                class="mr-wx"
                >选座售票</a-button
              >
            </template>
          </a-table>
        </div>
      </section>
      <!-- 底部按钮 -->
      <footer class="footer">
        <member-menu
          :vip="'appTheaterListVip'"
          @click="showMember = true"
        ></member-menu>
        <div
          v-permission="'appTheaterListCustomamount'"
          class="menu"
          @click="$router.push('/home/sell/CustomAmount')"
        >
          自定义金额<span class="f-key ml-1x" v-if="shortcutKey">(F2)</span>
        </div>
        <div
          v-permission="'appTheaterListTicketcollection'"
          class="menu"
          @click="
            routerTo('theater:pickTicket', '/theater/pick-ticket/PickTicket')
          "
        >
          <span
            >取票<span class="f-key ml-1x" v-if="shortcutKey">(F3)</span>
          </span>
        </div>
        <div
          v-permission="'appTheaterListSeatchange'"
          class="menu"
          @click="
            routerTo('theater:changeSeat', '/theater/change-seat/ChangeSeat')
          "
        >
          <span
            >换座<span class="f-key ml-1x" v-if="shortcutKey">(F4)</span>
          </span>
        </div>
      </footer>
    </div>

    <!-- 会员详情 -->
    <member-detail
      class="flex-grow mr-2x"
      v-show="showMember"
      format="lease"
      @close="showMember = false"
    ></member-detail>

    <!-- 加购列表 -->
    <additional-purchase
      v-if="showPurchase"
      @close="closeAdditional"
      :purchaseObj="purchaseObj"
      @open="showPurchase = true"
    >
    </additional-purchase>

    <!--右边-购物车-->
    <shopping-cart ref="shoppingCart"></shopping-cart>

    <!--选座弹窗-->
    <select-seat
      ref="selectSeat"
      :visible.sync="visible"
      :spuId="selectSpuId"
      @sure="sure"
    ></select-seat>
  </div>
</template>

<script>
import moment from "moment";
import { Empty } from "ant-design-vue";
import { mapGetters } from "vuex";
import AdditionalPurchase from "./components/AdditionalPurchase";
import { onkeydown } from "@/utils/global";
import MemberMenu from "../../../components/member/MemberMenu.vue";
import ShoppingCart from "./ShoppingCart";
import MemberDetail from "../../home/member/MemberDetail";
import {
  productFrontDramagoal,
  productFrontSessionbyTheater,
  productBackSite
} from "@/api/search";
import { theaterCart, theaterMore } from "@/api/cart";
import SelectSeat from "./components/SelectSeat";
export default {
  name: "TheaterList",
  components: {
    ShoppingCart,
    MemberMenu,
    SelectSeat,
    AdditionalPurchase,
    MemberDetail
  },
  data() {
    return {
      showMember: false,
      Empty,
      showCalendar: false, //日历弹窗
      calendar: "", //查看日历值
      specifyDate: "", //日历指定日期
      purchaseObj: {}, //加购数据
      showPurchase: false, //是否显示加购列表
      theaterCart,
      theaterMore,
      fieldList: [], //场地列表
      field: [], //场地id
      visible: false,
      defaultChecked: null, //默认选中剧目
      spuId: 0, //剧目spuId
      selectSpuId: "", //场次spuId
      dates: new Date(new Date().toLocaleDateString()).getTime(), //默认日期
      dateIndex: 0, //日期索引
      repertoireList: [], //剧目列表
      repertoireSecect: {}, //选择的剧目
      dataSource: [], //场次数据
      columns: [
        {
          title: "剧目名称",
          key: "1",
          dataIndex: "theaterName",
          align: "center",
          width: "100px",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "场地",
          key: "2",
          dataIndex: "placeName",
          align: "center",
          width: "100px"
        },
        {
          title: "时长",
          key: "3",
          dataIndex: "duration",
          align: "center",
          width: "100px",
          scopedSlots: { customRender: "duration" }
        },
        {
          title: "时间",
          key: "4",
          dataIndex: "time",
          align: "center",
          width: "100px",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "售价",
          key: "5",
          dataIndex: "priceRange",
          align: "center",
          width: "100px",
          scopedSlots: { customRender: "priceRange" }
        },
        {
          title: "剩余票数",
          key: "6",
          dataIndex: "unsold",
          align: "center",
          width: "100px"
        },
        {
          title: "操作",
          key: "7",
          dataIndex: "operation",
          align: "center",
          width: "100px",
          scopedSlots: { customRender: "operation" }
        }
      ], //场次title
      name: "" //剧目名称
    };
  },
  computed: {
    ...mapGetters({
      shortcutKey: "setting/getshortcutKey"
    }),
    //查询今天开始一个礼拜日期
    onDates() {
      let days = ["日", "一", "二", "三", "四", "五", "六"];
      let dates = new Date(),
        year = dates.getFullYear(),
        month = dates.getMonth() + 1,
        date = dates.getDate(),
        day = dates.getDay(),
        dateCopy = date + 1,
        isday = "",
        array = [month + "." + date + "今天"];
      let maxDate = new Date(year, month, 0).getDate(); //本月最多天数
      for (var i = 0; i < 6; i++) {
        date += 1;
        if (date > maxDate) {
          month += 1;
          date = 1;
        }
        if (month > 12) {
          month = 1;
        }
        let week = days[day >= 6 ? (day = 0) : (day += 1)];
        if (date == dateCopy) {
          isday = "明天";
        } else {
          isday = "星期";
        }
        array.push(month + "." + date + isday + (isday == "明天" ? "" : week));
      }
      return array;
    }
  },
  created() {
    this.onProductFrontDramagoal();
    this.productBackSite();
  },
  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown, true);
  },
  beforeDestroy() {
    //关闭键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
  },
  methods: {
    //场地change
    fieldChange() {
      this.onProductFrontDramagoal();
      this.getSessionbyTheater(this.spuId, this.dates);
    },
    //查询场地
    productBackSite() {
      productBackSite({ page: 1, size: 500 })
        .then(res => {
          this.fieldList = res.records;
        })
        .catch(() => {});
    },
    //查看日历
    calendarClick() {
      this.showCalendar = true;
      //有指定日期
      if (this.specifyDate) {
        this.calendar = moment(new Date(this.specifyDate));
      } else {
        //没有指定日期
        let date =
          new Date().getFullYear() +
          "." +
          this.onDates[this.dateIndex].replace(/[^\d.]/g, "");
        this.calendar = moment(new Date(date));
      }
    },
    //选择日历确定
    handleOk() {
      let check = moment(this.calendar).format("YYYY.MM.DD"),
        date = new Date(),
        year = check.substr(0, 4),
        month = check.substr(5),
        monArry = [];
      //年份是否相等
      if (date.getFullYear() == year) {
        if (month.at(0) === "0") {
          month = check.substr(6);
        }
        //提取一周日期，并处理格式为"2.16"
        this.onDates.forEach(item => {
          let num = item.replace(/[^\d.]/g, "");
          monArry.push(num);
        });
        let isTrue = monArry.findIndex(item => item == month);
        if (isTrue !== -1) {
          //在日期内
          this.specifyDate = "";
          this.onDateClick(this.onDates[isTrue], isTrue);
        } else {
          //指定日期
          this.specifyDates();
        }
      } else {
        //指定日期
        this.specifyDates();
      }
      this.showCalendar = false;
    },
    //指定日期时
    specifyDates() {
      this.dateIndex = -1;
      this.dates = new Date(this.calendar).getTime();
      this.specifyDate = moment(this.calendar).format("YYYY-MM-DD");
      this.getSessionbyTheater(
        this.spuId,
        new Date(this.specifyDate).getTime()
      );
    },
    //选择日历取消
    handleCancel() {
      this.showCalendar = false;
    },
    windowKeyDown(e) {
      if (!this.shortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    //根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      if (window.localStorage.getItem("shortcutKey") == "false") return false;
      switch (code) {
        case 112:
          //按了F1
          // this.$router.push({ path: "/home/sell/MemberDetail" });
          this.showMember = true;
          break;

        case 113:
          //按了F2
          this.$router.push({ path: "/home/sell/CustomAmount" });
          break;

        case 114:
          //按了F3
          this.routerTo(
            "theater:pickTicket",
            "/theater/pick-ticket/PickTicket"
          );
          break;

        case 115:
          // 按了F4
          this.routerTo(
            "theater:changeSeat",
            "/theater/change-seat/ChangeSeat"
          );
          break;

        default:
          break;
      }
    },
    //取票 换座
    routerTo(key, path) {
      this.$store.commit("common/setCurrentLeftMenuKey", key);
      this.$router.push(path);
    },
    closeAdditional() {
      this.showPurchase = false;
      //通知购物车加购完成了
      this.$refs.shoppingCart.getTheaterDetail();
      // this.$store.commit("ticketSales/setdoingAddPurchase", false);
    },
    //剧目搜索
    onSearch(value) {
      this.name = value;
      this.onProductFrontDramagoal();
    },
    //剧目列表
    onProductFrontDramagoal() {
      productFrontDramagoal({ name: this.name, placeId: this.field.join() })
        .then(res => {
          this.repertoireList = res;
          this.defaultChecked = res[0].id;
          this.spuId = res[0].spuId;
          this.getSessionbyTheater(res[0].spuId, this.dates);
        })
        .catch(() => {});
    },
    //场次列表
    getSessionbyTheater(theaterId, sessionDate) {
      productFrontSessionbyTheater({
        theaterId,
        sessionDate,
        placeId: this.field.join()
      })
        .then(res => {
          res.forEach(item => {
            item.key = item.id;
          });
          this.dataSource = res;
        })
        .catch(() => {});
    },
    //选择剧目
    onRepertoireChoice(row) {
      this.defaultChecked = row.id;
      this.spuId = row.spuId;
      this.dateIndex = 0;
      this.dates = new Date(new Date().toLocaleDateString()).getTime();
      this.getSessionbyTheater(this.spuId, this.dates);
    },
    //选择日期
    onDateClick(item, i) {
      this.specifyDate = "";
      this.dateIndex = i;
      let n = "";
      n = item.match(/\d+\.\d+/g)[0];
      this.dates = new Date(new Date().getFullYear() + "." + n).getTime();
      this.getSessionbyTheater(this.spuId, this.dates);
    },
    //选座售票
    onSelection(row) {
      let data = [];
      let {
        cartList: { productItemList }
      } = this.$refs.shoppingCart;
      //有购物车需回选
      if (productItemList && productItemList.length) {
        productItemList.forEach(list => {
          let { theaterTicketId, theaterTicketSpuId, theaterSeatInfo } = list;
          data.push({
            theaterTicketId,
            theaterTicketSpuId,
            seatIds: theaterSeatInfo.map(item => item.seatId)
          });
        });
        this.$refs.selectSeat.backData(data, row.spuId);
      }
      this.selectSpuId = row.spuId;
      let t = setTimeout(() => {
        this.visible = true;
        clearTimeout(t);
        t = null;
      }, 350);
    },
    //确认添加购物车
    sure(val) {
      let id = sessionStorage.getItem("theaterCartId");
      let data = { theaterList: val };
      let api = "";
      if (!id) {
        //第一次新增
        api = this.theaterCart;
      } else {
        //第二次更新
        api = this.theaterMore;
        data["id"] = id;
      }
      api(data)
        .then(res => {
          if (Object.keys(res).length) {
            sessionStorage.setItem("theaterCartId", res.id);
            this.$refs.shoppingCart.getTheaterDetail();
          }
          this.$refs.selectSeat.successSeat();
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.wrapper {
  /*width: 100%;*/
  /*margin-top: 0;*/
  .header {
    justify-content: flex-start;
    .field {
      min-width: 200px;
      max-width: 400px;
    }
    .bd {
      border: 2px solid rgb(65 130 252);
    }
    .search {
      background: white;
      padding: 16px;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .main {
    .repertoire {
      overflow: auto;
      background: white;
      padding: 12px;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);

      .repertoire-list {
        overflow: auto;
        li {
          width: 100px;
        }
        img {
          display: block;
          width: 100px;
          height: 100px;
          border: 3px solid transparent;
        }
        .active {
          color: @primary;
          img {
            border-color: @primary;
          }
        }
      }
    }
  }

  /*底部*/
  .footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    background: white;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    .menu {
      position: relative;
      flex-shrink: 0;
      width: 13%;
      margin-right: 10px;
      padding: 0 15px;
      border: 1px solid @primary;
      line-height: 38px;
      text-align: center;
      color: @primary;
      border-radius: 20px;
      cursor: pointer;
      &.action {
        color: #ffffff;
        background-color: @primary;
      }
      &.disabled {
        border-color: @disabled;
        color: @disabled;
        cursor: not-allowed;
        background: #f5f5f5;
      }
      .iconfont {
        font-size: 22px;
        position: absolute;
        top: -15px;
        right: -13px;
      }
    }
  }
}
</style>
