<!-- 取票 -->
<template>
  <div class="wrapper flex-between flex-col">
    <div class="header">
      <!--查询-->
      <a-form-model layout="inline" :model="searchForm" ref="searchForm">
        <a-form-model-item label="订单号" prop="orderId">
          <a-input
            v-model="searchForm.orderId"
            placeholder="请输入订单号"
            class="input"
            allow-clear
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input
            v-model="searchForm.phone"
            placeholder="请输入手机号"
            class="input"
            allow-clear
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="票号" prop="ticketNo">
          <a-input
            v-model="searchForm.ticketNo"
            placeholder="请输入票号"
            class="input"
            allow-clear
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="searchNormal">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="link" @click="visibleSearch = true"
            >高级查询</a-button
          >
        </a-form-model-item>
      </a-form-model>
      <!--高级查询-->
      <a-drawer
        title="高级查询"
        placement="right"
        :width="400"
        :closable="false"
        :visible="visibleSearch"
        @close="visibleSearch = false"
      >
        <a-form-model
          ref="heightForm"
          :model="searchForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
        >
          <a-form-model-item label="订单编号" prop="orderId">
            <a-input
              v-model="searchForm.orderId"
              placeholder="请输入订单编号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="取票号" prop="takeTicketNo">
            <a-input
              v-model="searchForm.takeTicketNo"
              placeholder="请输入取票号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="取票人手机号" prop="phone">
            <a-input
              v-model="searchForm.phone"
              placeholder="请输入取票人手机号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="取票人身份证" :colon="false">
            <id-card-input v-model="searchForm.idCard"></id-card-input>
          </a-form-model-item>
          <a-form-model-item label="票号" prop="ticketNo">
            <a-input
              v-model="searchForm.ticketNo"
              placeholder="请输入票号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="会员号码" prop="memberId">
            <a-input
              v-model="searchForm.memberId"
              placeholder="请输入会员号码"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="会员手机号" prop="memberPhone">
            <a-input
              v-model="searchForm.memberPhone"
              placeholder="请输入会员手机号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label=" " :colon="false">
            <a-button type="primary" class="mr-2x" @click="searchHigh"
              >查询</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-drawer>
    </div>
    <!-- 表格内容 -->
    <div class="main mt-2x" v-show="isShow">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :row-selection="rowSelection"
        :pagination="false"
        :expandRowByClick="true"
        :indentSize="20"
        :scroll="{ y: 400 }"
        :rowKey="record => record.id"
      >
        <div slot="useDate" slot-scope="row">
          <p>{{ row.useDate | date("YYYY-MM-DD") }}</p>
          <p v-if="row.startTime && row.endTime">
            {{ row.startTime }} ~ {{ row.endTime }}
          </p>
        </div>
        <div slot="whetherTakeTicket" slot-scope="row">
          <p v-if="row.whetherTakeTicket === 0">未取票</p>
          <p v-else>已取票</p>
        </div>
        <template slot="action" slot-scope="row">
          <a-button type="link p-0" @click="pickTicketBtn(row)">取票</a-button>
        </template>
      </a-table>
    </div>
    <!-- 底部 -->
    <div class="flex-end ticket-bottom" slot="footer" v-show="isShow">
      <a-button class="payment-btn" @click="batchOption" v-if="isShowAllBtn">
        批量取票
      </a-button>
      <a-button class="cancel-btn" @click="cancel">
        取消
      </a-button>
    </div>
  </div>
</template>

<script>
import IdCardInput from "../../../components/hardware/IdCardInput";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";
import { orderExchangeticket, orderTaketheatreticket } from "@/api/search";
import { ticketTake } from "@/api/order";
const columns = [
  {
    title: "票号",
    dataIndex: "auxiliaryId",
    key: "auxiliaryId"
  },
  {
    title: "剧目名称",
    dataIndex: "playName",
    key: "playName"
  },
  {
    title: "剧场票名称",
    dataIndex: "theaterTicketName",
    key: "theaterTicketName"
  },
  {
    title: "场地",
    dataIndex: "placeName",
    key: ""
  },
  {
    title: "场次时间",
    key: "useDate",
    scopedSlots: { customRender: "useDate" }
  },
  {
    title: "座位",
    key: "seatName",
    dataIndex: "seatName"
  },
  {
    title: "取票状态",
    key: "whetherTakeTicket",
    scopedSlots: { customRender: "whetherTakeTicket" }
  },
  {
    title: "取票号",
    key: "mainAuxiliaryId",
    dataIndex: "mainAuxiliaryId"
  },
  {
    title: "订单编号",
    key: "mainOrderId",
    dataIndex: "mainOrderId"
  },
  {
    title: "销售商户",
    key: "orderSaleMerchantName",
    dataIndex: "orderSaleMerchantName"
  },
  {
    title: "销售渠道",
    key: "buyChannelsText",
    dataIndex: "buyChannelsText"
  },
  {
    title: "取票手机号",
    key: "takeTicketPersonPhone",
    dataIndex: "takeTicketPersonPhone"
  },
  {
    title: "取票身份证",
    key: "takeTicketPersonIdCard",
    dataIndex: "takeTicketPersonIdCard"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
let selectId = []; //全选的ID
const rowSelection = {
  onChange: selectedRowKeys => {
    selectId = Array.from(selectedRowKeys);
  },
  //当已取票时，checkbox禁用掉，
  getCheckboxProps: record => {
    return {
      props: {
        disabled: record.whetherTakeTicket === 1 //0 未取票，1 为已取票
      }
    };
  }
};

export default {
  name: "PickTicket",
  components: { IdCardInput },
  data() {
    return {
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      //查询表单
      searchForm: {
        orderId: "", //订单号
        takeTicketNo: "", //取票号
        phone: "", //手机号
        idCard: "", //取票人身份证
        ticketNo: "", //票号
        memberId: "", //会员号码
        memberPhone: "" //会员手机号
      },
      visibleSearch: false, //是否展示高级查询
      columns, //表格字段
      rowSelection,
      selectId, //多选选中id
      tableData: [], //表格渲染数组
      isShowAllBtn: false, //是否显示批量取票按钮
      isShow: false //是否显示列表或底部内容
    };
  },
  created() {},
  methods: {
    //查询
    getList() {
      orderExchangeticket(this.searchForm)
        .then(res => {
          if (res.length === 0) this.$message.warning("没有查询到数据");
          if (res.length > 0) {
            this.isShow = true;
            this.tableData = res;
            let filterData = res.find(item => {
              return item.whetherTakeTicket === 0;
            });
            //列表存在未取票状态的数据，显示批量取票按钮
            if (filterData) {
              this.isShowAllBtn = true;
            }
          }
        })
        .catch(() => {});
    },
    // 查询
    reload(data) {
      this.page = 1;
      this.visible = false;
      this.getList(data);
    },
    // 普通查询
    searchNormal() {
      if (
        this.searchForm.orderId ||
        this.searchForm.phone ||
        this.searchForm.ticketNo
      ) {
        this.reload({
          orderId: this.searchForm.orderId, // 订单编号 1453982338270961665
          phone: this.searchForm.phone,
          ticketNo: this.searchForm.ticketNo
        });
      } else {
        this.$message.warning("请输入查询条件");
      }
    },
    // 高级查询
    searchHigh() {
      let condution = 0;
      for (let i in this.searchForm) {
        if (this.searchForm[i] !== "") {
          condution++;
        }
      }
      if (condution === 0) {
        this.$message.warning("请输入查询条件");
        return false;
      }
      this.reload({ ...this.searchForm });
    },
    // 重置表单
    reset() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.tableData = [];
    },
    //打印及改订单状态
    getPrint(selectId) {
      orderTaketheatreticket({
        ids: selectId
      }).then(res => {
        if (res) {
          HardwareService.printTicket(JSON.stringify(res));
          ticketTake({
            idList: selectId
          })
            .then(() => {
              this.getList();
            })
            .catch(() => {});
        }
      });
    },
    //取票
    pickTicketBtn(row) {
      let idList = row.id.split(",");
      this.$confirm({
        title: "确定要取票吗?",
        okText: "确定",
        onOk: () => {
          this.getPrint(idList);
        }
      });
    },
    //批量取票
    batchOption() {
      if (selectId.length > 0) {
        this.$confirm({
          title: "确定要取票吗?",
          okText: "确定",
          onOk: () => {
            this.getPrint(selectId);
          }
        });
      } else {
        this.$message.info("请勾选要取票的订单");
      }
    },
    //取消
    cancel() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
/*头部*/
.header {
  padding: 12px 16px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

/*中部*/
.main {
  padding: 16px;
  margin: 10px 0;
  height: 530px;
}

//底部
.ticket-bottom {
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 9px 0px rgba(116, 116, 116, 0.22);
  border-radius: 5px;
  // 底部通用按钮
  .cancel-btn,
  .payment-btn {
    width: 240px;
    height: 44px;
    line-height: 44px;
    border-radius: 22px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
  }
  // 取消按钮
  .cancel-btn {
    border: 1px solid #4182fc;
    color: #4182fc;
    margin-left: 30px;
  }
  // 批量操作按钮
  .payment-btn {
    background: linear-gradient(181deg, #44b8fb, #4d8afb);
    box-shadow: 3px 0px 9px 0px rgba(116, 116, 116, 0.26);
    color: white;
    padding: 0 20px;
  }
}
</style>
