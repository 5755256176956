<template>
  <footer class="footer">
    <div class="flex-between align-center">
      <div class="flex-grow">
        <div class="flex-start align-center">
          <!--支付方式-->
          <pay-way
            :cartId="cart.id"
            :preferential="
              cart.preferentialInfo &&
              cart.preferentialInfo.merchantDiscountAmount +
                cart.preferentialInfo.integralPayPrice >
                0
                ? true
                : false
            "
            @success="success"
          ></pay-way>
        </div>
      </div>

      <ul class="text-r">
        <li class="mr-2x">
          <span class="dark">结算金额：</span>
          <p class="ib price font-xxxl" style="min-width: 84px">
            {{ cart.settlementPrice | money }}
          </p>
        </li>
      </ul>
    </div>

    <!--功能菜单-->
    <div class="flex-between align-center mt-2x">
      <div class="flex-start flex-grow">
        <div class="f-btn relative" @click="showMemberClick">
          <a-button
            v-permission="'appTheaterSellMember'"
            size="large"
            shape="round"
            :type="memberInfo.id ? 'primary' : ''"
            class="res"
          >
            <span
              v-if="memberInfo.id && memberInfo.whetherTemporaryMember === 1"
              >临时会员</span
            >
            <span v-else-if="memberInfo.id">{{ memberInfo.mobile }}</span>
            <span v-else>会员</span>
          </a-button>
          <i
            class="iconfont iconicon--shanchu red"
            v-if="memberInfo.id"
            @click.stop="clearMember"
          ></i>
        </div>
        <a-button
          v-permission="'appTheaterSellMemberRecharge'"
          size="large"
          shape="round"
          class="f-btn"
          @click="memberRecharge"
          >会员充值</a-button
        >
        <a-button
          v-permission="'appTheaterSellDirectPayment'"
          size="large"
          shape="round"
          class="f-btn"
          @click="showPayOnly"
          >直接付款</a-button
        >
        <a-button
          v-permission="'appTheaterSellTakeTicket'"
          v-if="currentTopMenuKey !== 'appRetailNew'"
          size="large"
          shape="round"
          class="f-btn"
          @click="changeTakeTicket"
          >取票</a-button
        >
      </div>
      <div>
        <!-- <a-button v-if="getSettings.IsShowCart" size="large" type="primary" shape="round" ghost>
          <a-icon type="shopping-cart" class="font-xxl" />
          <span>购物车</span>
        </a-button> -->
        <a-button
          v-if="!getSettings.IsShowSpeedOrder"
          size="large"
          type="primary"
          shape="round"
          class="submit"
          @click="submit"
          >提交订单</a-button
        >
      </div>
    </div>

    <!--取单-->
    <take-cart
      :card-id="cart.id"
      :visible.sync="showCartList"
      ref="ProductCalcFooterCart"
      @change="changeCart"
    ></take-cart>
    <!-- 取票 -->
    <take-ticket :visible.sync="showTakeTickett"></take-ticket>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import TakeCart from "../../../../components/sell/commonents/TakeCart";
import TakeTicket from "../../../../components/sell/commonents/TakeTicket.vue";
import mixins from "../../../../components/sell/mixins";
import { commonMember } from "../../../../api/cart";
import PayWay from "../../../../components/sell/commonents/PayWay.vue";

export default {
  name: "ProductCalcFooter",
  components: {
    TakeCart,
    PayWay,
    TakeTicket
  },

  mixins: [mixins],
  data() {
    return {
      // 优惠类型
      discountType: {
        A: "整单折扣",
        B: "会员折扣",
        C: "优惠券",
        D: "营销活动"
      },
      // 购物车对象
      cart: {
        id: 0,
        totalPrice: 0, // 总价
        settlementPrice: 0, // 结算金额
        discountAmount: 0, // 优惠金额
        productNum: 0,
        travelerNum: 0,
        preferentialInfo: null, // 优惠对象
        totalProductList: [] // 产品列表
      },
      buyNumList: {}, // 产品数量明细
      orderId: "", // 订单id
      ownerFormatTypes: [], // 产品类型集合
      showCartList: false, // 取单弹窗
      showTakeTickett: false, // 取票弹窗
      showDiscount: false // 选择优惠弹窗
    };
  },

  computed: {
    ...mapGetters({
      // 页面布局
      getSettings: "setting/getSettings"
    }),
    ...mapGetters("common", ["memberInfo"]),
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    }
  },

  watch: {
    "memberInfo.id"(val) {
      // 如果切换了会员，则需要通知购物车
      if (this.cart.id) {
        this.updateMember(val);
      }
    }
  },

  methods: {
    // 显示取票弹窗
    changeTakeTicket() {
      this.showTakeTickett = true;
    },
    // 更新购物车
    updateCart(res) {
      this.cart = res;
      const obj = {};
      // 产品数量明细
      res.productList?.forEach(item => {
        if (obj[item.productId]) {
          obj[item.productId].num++;
        } else {
          obj[item.productId] = {
            productId: item.productId,
            productName: item.productName,
            num: item.productFlagPO.buyNum
          };
        }
      });
      this.buyNumList = obj;
    },
    // 修改会员
    updateMember(val) {
      const obj = {
        cartId: this.cart.id
      };
      if (val) {
        obj.purchaserMemberId = val;
      }
      commonMember({
        cartId: this.cart.id,
        purchaserMemberId: val
      }).then(res => {
        this.changeCart(res);
      });
    },
    // 展示会员
    showMemberClick() {
      this.$emit("member", true);
    },
    // 会员充值
    memberRecharge() {
      this.$router.push({ path: "/home/member/MemberRecharge" });
    },
    // 展示直接付款
    showPayOnly(val) {
      this.$emit("pay-only", val);
    },
    // 提交订单
    submit() {
      this.$message.warning("该功能待开发");
    },
    // 支付成功
    success() {
      this.$emit("success", true);
    },

    // 取单
    getCart() {
      this.$refs.ProductCalcFooterCart.getList();
    },
    changeCart(res) {
      this.$emit("change-cart", res);
    },
    clearMember() {
      //清除会员信息
      this.$store.commit("common/UpdateMember", {});
    },
    // 选择优惠弹窗
    checkDiscount() {
      if (!this.cart.id) {
        this.$message.warning("请选购产品");
      } else {
        this.showDiscount = true;
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../../assets/less/custom.less";
/*底部*/
.footer {
  z-index: 1;
  padding: 16px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  background: #fff;
  .iconfont {
    font-size: 22px;
    position: absolute;
    top: -15px;
    right: -13px;
    cursor: pointer;
  }
  .f-btn {
    /*font-size: 14px !important;*/
    min-width: 160px;
    margin-right: 16px;
  }
  .submit {
    width: 250px;
    margin-left: 16px;
  }

  /*确认订单弹窗*/
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
    background: rgba(12, 12, 12, 0.42);
    .center {
      width: 480px;
      padding: 24px;
      border-radius: 17px;
      background: #fff;
      z-index: 3;
      .modal-radio {
        display: block;
        height: 40px;
        margin-bottom: 12px;
        line-height: 40px;
      }
    }
  }
}
</style>
