<!-- 换座-->
<template>
  <div class="wrapper flex-between flex-col">
    <header class="header">
      <!--查询-->
      <a-form-model layout="inline" :model="searchForm" ref="searchForm">
        <a-form-model-item label="订单号" prop="orderId">
          <a-input
            v-model="searchForm.orderId"
            placeholder="请输入订单号"
            class="input"
            allow-clear
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input
            v-model="searchForm.phone"
            placeholder="请输入手机号"
            class="input"
            allow-clear
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="票号" prop="ticketNo">
          <a-input
            v-model="searchForm.ticketNo"
            placeholder="请输入票号"
            class="input"
            allow-clear
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="searchNormal">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="link" @click="visibleSearch = true"
            >高级查询</a-button
          >
        </a-form-model-item>
      </a-form-model>
      <!--高级查询-->
      <a-drawer
        title="高级查询"
        placement="right"
        :width="400"
        :closable="false"
        :visible="visibleSearch"
        @close="visibleSearch = false"
      >
        <a-form-model
          ref="heightForm"
          :model="searchForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
        >
          <a-form-model-item label="订单编号" prop="orderId">
            <a-input
              v-model="searchForm.orderId"
              placeholder="请输入订单编号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="取票号" prop="takeTicketNo">
            <a-input
              v-model="searchForm.takeTicketNo"
              placeholder="请输入取票号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="取票人手机号" prop="phone">
            <a-input
              v-model="searchForm.phone"
              placeholder="请输入取票人手机号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="取票人身份证" :colon="false">
            <id-card-input v-model="searchForm.idCard"></id-card-input>
          </a-form-model-item>
          <a-form-model-item label="票号" prop="ticketNo">
            <a-input
              v-model="searchForm.ticketNo"
              placeholder="请输入票号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="会员号码" prop="memberId">
            <a-input
              v-model="searchForm.memberId"
              placeholder="请输入会员号码"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="会员手机号" prop="memberPhone">
            <a-input
              v-model="searchForm.memberPhone"
              placeholder="请输入会员手机号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label=" " :colon="false">
            <a-button type="primary" class="mr-2x" @click="searchHigh"
              >查询</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-drawer>
    </header>
    <!-- 表格内容 -->
    <section class="main mt-2x" v-show="isShow">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :expandRowByClick="true"
        :indentSize="20"
        :scroll="{ x: 1336, y: 400 }"
        :rowKey="record => record.id"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange
        }"
      >
        <div slot="useDate" slot-scope="row">
          <span>{{ row.useDate | date("YYYY-MM-DD") }}</span>
          <span v-if="row.startTime && row.endTime">
            {{ row.startTime }} ~ {{ row.endTime }}
          </span>
        </div>
        <div slot="whetherTakeTicket" slot-scope="row">
          <p v-if="row.whetherTakeTicket === 0">未取票</p>
          <p v-else>已取票</p>
        </div>
        <template slot="action" slot-scope="row">
          <!--
  订单状态：1未支付，2已取消，3已支付，4未激活，5未使用，6已使用，
  7待发货，8待收货，9已完成，10已过期，11已补办，12已退款  status; -->
          <a-button
            v-permission="'appTheaterChangeSeatchange'"
            type="link p-0"
            :disabled="![1, 3, 4, 5].includes(row.status)"
            @click="changeSeatBtn(row)"
            >换座</a-button
          >
        </template>
      </a-table>
    </section>
    <!--提示-->
    <h1 class="center disabled" v-show="!isShow">
      请输入订单号/手机号/票号查询
    </h1>
    <!--底部-->
    <footer class="footer" v-show="isShow">
      <div class="flex-grow">
        <a-button
          v-permission="'appTheaterChangeBatchseatchange'"
          type="primary"
          ghost
          @click="batchChange"
          >批量换座</a-button
        >
      </div>
    </footer>
    <!--换座弹窗-->
    <change-modal
      :visible.sync="visible"
      :spu-id="spuId"
      :now-seats="rows"
      :place-name="placeName"
      @ok="changeOk"
    ></change-modal>
  </div>
</template>

<script>
import IdCardInput from "../../../components/hardware/IdCardInput";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";
import { orderExchangeticket, orderTaketheatreticket } from "@/api/search";
import { ticketTake } from "@/api/order";
import ChangeModal from "./ChangeModal";
const columns = [
  {
    title: "票号",
    dataIndex: "auxiliaryId",
    key: "auxiliaryId",
    width: 130
  },
  {
    title: "剧目名称",
    dataIndex: "playName",
    key: "playName",
    width: 150
  },
  {
    title: "剧场票名称",
    dataIndex: "theaterTicketName",
    key: "theaterTicketName",
    width: 150
  },
  {
    title: "场地",
    dataIndex: "placeName",
    key: "",
    width: 120
  },
  {
    title: "场次时间",
    key: "useDate",
    scopedSlots: { customRender: "useDate" },
    width: 250
  },
  {
    title: "座位",
    key: "seatName",
    dataIndex: "seatName",
    width: 120
  },
  {
    title: "取票状态",
    key: "whetherTakeTicket",
    scopedSlots: { customRender: "whetherTakeTicket" },
    width: 100
  },
  {
    title: "取票号",
    key: "mainAuxiliaryId",
    dataIndex: "mainAuxiliaryId",
    width: 130
  },
  {
    title: "订单编号",
    key: "mainOrderId",
    dataIndex: "mainOrderId",
    width: 180
  },
  {
    title: "销售商户",
    key: "orderSaleMerchantName",
    dataIndex: "orderSaleMerchantName",
    width: 150
  },
  {
    title: "销售渠道",
    key: "buyChannelsText",
    dataIndex: "buyChannelsText",
    width: 120
  },
  {
    title: "取票手机号",
    key: "takeTicketPersonPhone",
    dataIndex: "takeTicketPersonPhone",
    width: 130
  },
  {
    title: "取票身份证",
    key: "takeTicketPersonIdCard",
    dataIndex: "takeTicketPersonIdCard",
    width: 150
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 100,
    fixed: "right"
  }
];

export default {
  name: "ChangeSeat",
  components: { IdCardInput, ChangeModal },
  data() {
    return {
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      //查询表单
      searchForm: {
        orderId: "", //订单号
        takeTicketNo: "", //取票号
        phone: "", //手机号
        idCard: "", //取票人身份证
        ticketNo: "", //票号
        memberId: "", //会员号码
        memberPhone: "" //会员手机号
      },
      visibleSearch: false, //是否展示高级查询
      columns, //表格字段
      selectedRowKeys: [], // 选中的行keys
      tableData: [], //表格渲染数组
      isShow: false, //是否显示列表
      // 换座弹窗
      visible: false, // 展示换座弹窗
      rows: [], // 选中的行
      spuId: "", // 场次spuId 1456097657276243969
      placeName: "", // 场次spuId 1456097657276243969
      printId: [] //打印用的id
    };
  },
  created() {},
  methods: {
    //查询
    getList() {
      orderExchangeticket(this.searchForm)
        .then(res => {
          if (res.length === 0) this.$message.warning("没有查询到数据");
          else {
            this.isShow = true;
          }
          this.tableData = res;
        })
        .catch(() => {});
    },
    // 查询
    reload(data) {
      this.page = 1;
      this.visible = false;
      this.getList(data);
    },
    // 普通查询
    searchNormal() {
      if (
        this.searchForm.orderId ||
        this.searchForm.phone ||
        this.searchForm.ticketNo
      ) {
        this.reload({
          orderId: this.searchForm.orderId, // 订单编号 1453982338270961665
          phone: this.searchForm.phone,
          ticketNo: this.searchForm.ticketNo
        });
      } else {
        this.$message.warning("请输入查询条件");
      }
    },
    // 高级查询
    searchHigh() {
      let condution = 0;
      for (let i in this.searchForm) {
        if (this.searchForm[i] !== "") {
          condution++;
        }
      }
      if (condution === 0) {
        this.$message.warning("请输入查询条件");
        return false;
      }
      this.reload({ ...this.searchForm });
    },
    // 重置表单
    reset() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.tableData = [];
    },
    //换座位
    changeSeatBtn(row) {
      this.rows = [row];
      this.spuId = row.spuId;
      this.printId = row.id.split(",");
      this.placeName = row.placeName;
      this.visible = true;
      this.selectedRowKeys = [];
    },
    // 勾选改变
    onSelectChange(selectedRowKeys, selectedRows) {
      //获取列表选中id
      this.printId = Array.from(selectedRowKeys);

      let different = false; // 不同座位票
      for (let item of selectedRows) {
        let same = true;
        for (let temp of selectedRows) {
          // 不同场次
          if (temp.spuId !== item.spuId) {
            same = false;
            different = true;
            this.$message.error("只能勾选同一场次的选项");
            break;
          }
          // 不同票档
          else if (temp.ticketLevelId !== item.ticketLevelId) {
            same = false;
            different = true;
            this.$message.error("只能勾选同一票档的选项");
            break;
          }
          // 不同剧场票
          else if (
            temp.theaterTicketSpuId !== item.theaterTicketSpuId ||
            temp.theaterTicketId !== item.theaterTicketId
          ) {
            same = false;
            different = true;
            this.$message.error("只能勾选同一剧场票的选项");
            break;
          }
        }
        if (!same) break;
      }
      if (!different) {
        this.selectedRowKeys = selectedRowKeys;
        this.rows = selectedRows;
      }
    },
    // 批量换座
    batchChange() {
      if (this.rows.length) {
        const row = this.rows[0];
        this.spuId = row.spuId;
        this.placeName = row.placeName;
        this.visible = true;
      } else {
        this.$message.warning("请勾选选项");
      }
    },
    // 确定换座
    changeOk() {
      this.rows = [];
      this.selectedRowKeys = [];
      this.getList();
      this.getPrint(this.printId);
    },
    //打印及改订单状态
    getPrint(selectId) {
      orderTaketheatreticket({
        ids: selectId
      }).then(res => {
        if (res) {
          HardwareService.printTicket(JSON.stringify(res));
          ticketTake({
            idList: selectId
          })
            .then(() => {
              this.getList();
            })
            .catch(() => {});
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
/*头部*/
.header {
  padding: 12px 16px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
