<!--
  @name: 选择座位弹窗
  @date: 2021-10-27
  @author: 易远胜
  @prop: {
    visible: 是否展示弹窗
    spuId: 场次的spuId
  }
  @event: sure // 确定选座，返回购物车需要的数据
                  [{theaterTicketId: "1", theaterTicketSpuId: "2",  seatIds: [3...]}]
  @method: backData /**
                     *@name: 重现选座回显方法
                     *@description: 父组件通过$refs调用该方法，传入的数组（arr）与选座返回的数组格式一致
                     *              调用该方法后会自动打开弹窗
                     *@date: 2020-10-29
                     *@author: 易远胜
                     *@params: {Array} arr 必传 回显的数据，与选座确定返回的数据格式一致
                     *@params: {String} spuId 必传,场次spuId,更新id
                
                     *@return: 无
                     */
 
-->
<template>
  <a-modal
    title="选择座位"
    :footer="null"
    :mask-closable="false"
    :visible="visible"
    @cancel="close"
    width="1300px"
  >
    <div class="flex-start">
      <!--左边座位-->
      <seat-list
        :horizontal-nums="horizontalNums"
        :vertical-nums="verticalNums"
        :seat-list="seatList"
        @change="seatChange"
        @set-theater-ticket="setTheaterTicket"
      ></seat-list>
      <!--右边表单-->
      <aside class="right flex-start flex-col flex-shrink">
        <div class="section flex-grow flex-start flex-col">
          <!--预留操作-->
          <div class="preview">
            <p class="font-l mb-1x">预留操作</p>
            <a-form-model
              ref="form"
              :model="form"
              :rules="rules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-form-model-item label="姓名" prop="name">
                <a-input
                  v-model="form.name"
                  placeholder="请输入预留人姓名"
                  allow-clear
                />
              </a-form-model-item>
              <a-form-model-item label="手机号" prop="phone">
                <a-input
                  v-model="form.phone"
                  placeholder="请输入预留人手机号"
                  allow-clear
                  v-inputfocus
                />
              </a-form-model-item>
            </a-form-model>
            <!--按钮-->
            <div class="flex-around">
              <a-button
                type="primary"
                @click="searchPreview"
                :loading="loading1"
                >查询</a-button
              >
              <a-button
                type="primary"
                ghost
                @click="reservedOk"
                :loading="loading2"
                >预留</a-button
              >
              <a-button @click="cancelReserved" :loading="loading3"
                >取消预留</a-button
              >
            </div>
          </div>

          <!--已选座位-->
          <div class="checked flex-grow">
            <a-row type="flex" align="middle" class="mb-2x">
              <a-col :span="9" class="font-l">已选剧场票</a-col>
              <a-col :span="15">
                <a-select
                  v-model="theaterTicketId"
                  class="res"
                  placeholder="请选择"
                  @change="theaterTicketIdChange"
                >
                  <a-select-option
                    :value="item.skuIds[0]"
                    v-for="(item, index) in theaterTickets"
                    :key="index"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>

            <!--已选中列表-->
            <a-row :gutter="[16, 16]">
              <a-col
                :span="12"
                v-for="(item, index) in checkedSets"
                :key="index"
                @click="clearItem(item, index)"
              >
                <div class="item">
                  <span>{{ item.productName }}</span>
                  <a-icon type="close-circle" class="font-xl icon" />
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <!--底部-->
        <footer class="right-footer flex-shrink">
          <a-button
            type="primary"
            class="res"
            size="large"
            shape="round"
            @click="sure"
            >确定选座（{{ checkedSets.length }}）</a-button
          >
        </footer>
      </aside>
    </div>
  </a-modal>
</template>

<script>
import {
  cancelSeatReservation,
  productPlaceTicketInfo,
  seatBook,
  seatReservation
} from "../../../../api/product";
import { checkPhone, checkText } from "../../../../utils/global";
import SeatList from "./SeatList";

export default {
  name: "SelectSeat",
  components: { SeatList },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 场次id
    spuId: {
      type: String,
      default: ""
    },
    // 重新选座回显对象
    backSeat: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      loading1: false,
      loading2: false,
      loading3: false,
      id: -1,
      form: {
        name: "",
        phone: ""
      },
      rules: {
        name: [
          {
            validator: checkText,
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            validator: checkPhone,
            trigger: "blur"
          }
        ]
      },
      theaterTicketId: "", // 剧场票类型
      theaterTicketSpuId: "", // 剧场票类型 spuid
      verticalNums: [], // 垂直编号 行
      horizontalNums: [], // 水平编号列
      seatList: [], // 座位列表
      checkedSets: [], // 选中座位
      ticketLevels: [], // 票档
      theaterTickets: [] // 剧场票
    };
  },
  created() {},
  watch: {
    visible(show) {
      if (show && this.id !== this.spuId) {
        this.id = this.spuId;
        this.loadDetail();
      }
    }
  },
  methods: {
    // 加载场次详情
    loadDetail() {
      return productPlaceTicketInfo({
        spuId: this.id
      })
        .then(({ areas, ticketLevels, theaterTickets }) => {
          const { seats, horizontalNums, verticalNums } = areas[0];
          seats.forEach(item => {
            item.forEach(temp => {
              temp.selected = false;
              // 给座位添加一个剧场票id字段
              temp.theaterTicketSpuId = "";
              temp.theaterTicketId = "";
            });
          });
          this.seatList = seats;
          this.horizontalNums = horizontalNums;
          this.verticalNums = verticalNums;
          this.ticketLevels = ticketLevels;
          this.theaterTickets = theaterTickets;
          // 默认选中剧场票
          if (theaterTickets.length) {
            this.theaterTicketSpuId = theaterTickets[0].id;
            this.theaterTicketId = theaterTickets[0].skuIds[0];
          }
        })
        .catch(() => {
          console.log("获取座位列表失败");
        });
    },
    // 清除座位
    clearItem(item, index) {
      item.selected = false;
      this.setTheaterTicket(item, false);
      this.checkedSets.splice(index, 1);
    },
    // 给座位设置剧场票id, 用于后面添加购物车用
    setTheaterTicket(item, checked) {
      if (checked) {
        item.theaterTicketId = this.theaterTicketId;
        item.theaterTicketSpuId = this.theaterTicketSpuId;
      } else {
        item.theaterTicketId = "";
        item.theaterTicketSpuId = "";
      }
    },
    // 选座座位改变
    seatChange(arr) {
      this.form.name = "";
      this.form.phone = "";
      this.checkedSets = arr;
    },
    // 关闭弹窗
    close() {
      this.$refs.form.clearValidate();
      this.seatList.forEach(item => {
        item.forEach(temp => {
          temp.selected = false;
        });
      });
      this.checkedSets = [];
      this.$emit("update:visible", false);
    },
    // 选择剧场票
    theaterTicketIdChange(val) {
      const temp = this.theaterTickets.find(item => item.skuIds[0] === val);
      this.theaterTicketSpuId = temp.id;
    },
    // 校验表单
    checkForm() {
      let pass = true;
      this.$refs.form.validate(valid => {
        if (!valid) {
          this.$message.warning("验证不通过，请重新检查");
          pass = false;
        }
      });
      return pass;
    },
    // 查询预留
    searchPreview() {
      if (this.checkForm()) {
        this.loading1 = true;
        seatBook({
          placeTicketSpuId: this.id,
          phone: this.form.phone,
          name: this.form.name
        })
          .then(res => {
            if (res.length) {
              this.checkedSets = [];
              this.seatList.forEach(item => {
                item.forEach(temp => {
                  temp.selected = false;
                  if (res.find(val => val.id === temp.id)) {
                    temp.theaterTicketId = this.theaterTicketId;
                    temp.theaterTicketSpuId = this.theaterTicketSpuId;
                    this.checkedSets.push(temp);
                  }
                });
              });
            } else {
              this.$message.info("未查询到有预留座位信息");
            }
          })
          .catch(() => {})
          .finally(() => {
            this.loading1 = false;
          });
      }
    },
    // 确定预留
    reservedOk() {
      if (this.checkForm()) {
        const productDetailIds = this.checkedSets.map(item => item.id);
        if (productDetailIds.length === 0) {
          this.$message.error("请选择要预留的座位");
          return;
        }
        this.loading2 = true;
        seatReservation({
          spuId: this.spuId,
          productDetailIds,
          reserveName: this.form.name,
          reservePhone: this.form.phone
        })
          .then(() => {
            this.loadDetail();
            this.$message.success("预留成功");
            this.form.name = "";
            this.form.phone = "";
            this.checkedSets = [];
          })
          .catch(() => {})
          .finally(() => {
            this.loading2 = false;
          });
      }
    },
    // 取消预留接口
    cancelReserved() {
      if (this.checkedSets.length === 0) {
        this.$message.error("请先查询出预留座位列表");
        return;
      } else if (this.checkedSets.some(item => item.productSaleStatus !== 5)) {
        this.$message.error("选中的座位没有包含可取消的预留座位");
        return;
      }
      this.loading3 = true;
      cancelSeatReservation({
        spuId: this.spuId,
        productDetailIds: this.checkedSets.map(item => item.id)
      })
        .then(() => {
          this.$message.success("已取消预留");
          this.loadDetail();
          this.form.name = "";
          this.form.phone = "";
          this.checkedSets = [];
        })
        .catch(() => {})
        .finally(() => {
          this.loading3 = false;
        });
    },
    // 确定选座
    sure() {
      if (this.checkedSets.length === 0) {
        this.$message.error("请选择座位");
        return;
      }
      // 分剧场票，生成座位数组
      const theaterList = [];
      const obj = {};
      this.checkedSets.forEach(item => {
        if (!obj[item.theaterTicketId]) {
          obj[item.theaterTicketId] = {
            theaterTicketId: item.theaterTicketId,
            theaterTicketSpuId: item.theaterTicketSpuId,
            seatIds: [item.id]
          };
        } else {
          obj[item.theaterTicketId].seatIds.push(item.id);
        }
      });

      for (let key in obj) {
        theaterList.push(obj[key]);
      }
      this.$emit("sure", theaterList);
    },
    //选座加购物车调接口成功后，才关掉选座弹窗，避免选好的座位要重新选择
    successSeat() {
      this.close();
    },
    /**
     *@name: 重现选座回显方法
     *@description: 父组件通过$refs调用该方法，传入的数组（arr）与选座返回的数组格式一致
     *              调用该方法后会自动打开弹窗
     *@date: 2020-10-29
     *@author: 易远胜
     *@params: {Array} arr 必传 回显的数据，与选座确定返回的数据格式一致
     *@params: {String} spuId 必传,场次spuId,更新id

     *@return: 无
     */
    backData(arr, spuId) {
      this.id = spuId;
      this.seatList = [];
      this.loadDetail()
        .then(() => {
          this.$emit("update:visible", true);
          this.checkedSets = [];
          arr.forEach(item => {
            item.seatIds.forEach(temp => {
              let finded = false;
              for (let val of this.seatList) {
                for (let obj of val) {
                  if (temp === obj.id) {
                    obj.theaterTicketId = item.theaterTicketId;
                    obj.theaterTicketSpuId = item.theaterTicketSpuId;
                    obj.selected = true;
                    this.checkedSets.push(obj);
                    finded = true;
                    break;
                  }
                }
                if (finded) break;
              }
            });
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../../assets/less/custom";
/*右侧表单*/
.right {
  width: 300px;
  .section {
    height: 524px;
    overflow-y: auto;
    .preview,
    .checked {
      padding: 16px;
      border-radius: 8px;
      background: @primary-light2;
    }
    .preview {
      padding: 16px 16px 20px;
      .btn {
        width: 100px;
      }
    }

    .checked {
      margin-top: 12px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .item {
        position: relative;
        border: 1px solid @primary;
        border-radius: 4px;
        padding: 8px;
        text-align: center;
        color: @primary;
        cursor: pointer;
        background: #fff;
        .icon {
          position: absolute;
          top: -8px;
          right: -8px;
          background: @bgColor;
          color: @disabled;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .right-footer {
    padding: 12px 16px;
    border-top: 1px solid @primary-light1;
    background: @primary-light2;
  }
}
</style>
