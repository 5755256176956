<!--换座弹窗-->
<template>
  <a-modal
    :title="`更换座位（${placeName} - ${areaName}）`"
    :footer="null"
    :mask-closable="false"
    :visible="visible"
    @cancel="close"
    width="1300px"
  >
    <div class="flex-start">
      <!--左边座位-->
      <seat-list
        :horizontal-nums="horizontalNums"
        :vertical-nums="verticalNums"
        :seat-list="seatList"
        @change="seatChange"
      ></seat-list>
      <!--右边表单-->
      <aside class="right flex-start flex-col">
        <div class="section flex-grow flex-start flex-col">
          <!--当前座位-->
          <div class="checked flex-grow">
            <p class="font-l mb-1x">当前座位（{{ nowSeats.length }}）</p>
            <a-row :gutter="[16, 16]">
              <a-col :span="12" v-for="(item, index) in nowSeats" :key="index">
                <div class="item">
                  <span>{{ item.seatName }}</span>
                </div>
              </a-col>
            </a-row>
          </div>

          <!--更换座位-->
          <div class="checked flex-grow mt-2x">
            <p class="font-l mb-1x">更换座位（{{ checkedSets.length }}）</p>
            <!--已选中列表-->
            <a-row :gutter="[16, 16]">
              <a-col
                :span="12"
                v-for="(item, index) in checkedSets"
                :key="index"
                @click="clearItem(item, index)"
              >
                <div class="item">
                  <span>{{ item.productName }}</span>
                  <a-icon type="close-circle" class="font-xl icon" />
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <!--底部-->
        <footer class="right-footer flex-shrink">
          <a-button
            type="primary"
            class="res"
            size="large"
            shape="round"
            @click="sure"
            :loading="loading"
            >确定选座</a-button
          >
        </footer>
      </aside>
    </div>
  </a-modal>
</template>

<script>
import SeatList from "../sell/components/SeatList";
import { productPlaceTicketInfo } from "../../../api/product";
import { changeSeat } from "../../../api/order";
export default {
  name: "ChangeModal",
  components: { SeatList },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 场次id
    spuId: {
      type: String,
      default: ""
    },
    // 场地名称
    placeName: {
      type: String,
      default: ""
    },
    // 当前座位
    nowSeats: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      loading: false,
      verticalNums: [], // 垂直编号 行
      horizontalNums: [], // 水平编号列
      seatList: [], // 座位列表
      checkedSets: [],
      areaId: "", // 区域id
      areaName: "" // 区域名称
    };
  },
  watch: {
    visible(val) {
      if (val) this.loadDetail();
    }
  },
  methods: {
    // 加载座位详情
    loadDetail() {
      productPlaceTicketInfo({
        spuId: this.spuId
      })
        .then(({ areas, ticketLevels }) => {
          const {
            seats,
            horizontalNums,
            verticalNums,
            areaId,
            areaName
          } = areas[0];
          seats.forEach(item => {
            item.forEach(temp => {
              temp.color = "#fff";
              // 给座位票档添加颜色
              for (let val of ticketLevels) {
                if (val.id === temp.ticketLevelId) {
                  temp.color = val.color;
                  break;
                }
              }
              temp.selected = false;
              // 给座位添加一个剧场票id字段
              temp.theaterTicketSpuId = "";
              temp.theaterTicketId = "";
            });
          });
          this.seatList = seats;
          this.horizontalNums = horizontalNums;
          this.verticalNums = verticalNums;
          this.areaId = areaId;
          this.areaName = areaName;
        })
        .catch(() => {});
    },
    // 选择座位
    seatChange(arr) {
      if (arr.length > this.nowSeats.length) {
        arr.forEach(item => {
          const obj = this.checkedSets.find(temp => temp.id === item.id);
          if (!obj) item.selected = false;
        });
        this.$message.error(`最多只能选择${this.nowSeats.length}个座位`);
      } else {
        // 过滤出不同票档座位
        const same = [];
        const diff = [];
        arr.forEach(item => {
          const obj = this.nowSeats[0];
          if (item.ticketLevelId !== obj.ticketLevelId) {
            diff.push(item);
          } else {
            same.push(item);
          }
        });
        if (diff.length) {
          this.$message.error("请选择相同票档的座位");
          diff.forEach(item => {
            item.selected = false;
          });
          this.checkedSets = same;
        } else {
          this.checkedSets = arr;
        }
      }
    },
    // 清除座位
    clearItem(item, index) {
      item.selected = false;
      this.checkedSets.splice(index, 1);
    },
    // 关闭弹窗
    close() {
      this.seatList.forEach(item => {
        item.forEach(temp => {
          temp.selected = false;
        });
      });
      this.checkedSets = [];
      this.$emit("update:visible", false);
    },
    // 确认选座
    sure() {
      if (this.checkedSets.length !== this.nowSeats.length) {
        this.$message.error("当前座位数量与更换座位数量不一致");
        return;
      }
      this.loading = true;
      changeSeat({
        idList: this.nowSeats.map(item => item.id),
        changeSeatList: this.checkedSets.map(item => ({
          seatId: item.id,
          seatName: item.productName,
          areaId: this.areaId,
          areaName: this.areaName,
          ticketLevelId: item.ticketLevelId,
          ticketLevelName: item.name
        }))
      })
        .then(() => {
          this.$message.success("更换成功");
          this.close();
          this.$emit("ok", true);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../assets/less/custom";
/*右侧表单*/
.right {
  width: 300px;
  .section {
    height: 524px;
    overflow-y: auto;
    .preview,
    .checked {
      padding: 16px;
      border-radius: 8px;
      background: @primary-light2;
    }
    .preview {
      .btn {
        width: 100px;
      }
    }

    .checked {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .item {
        position: relative;
        border: 1px solid @primary;
        border-radius: 4px;
        padding: 8px;
        text-align: center;
        color: @primary;
        cursor: pointer;
        background: #fff;
        &:hover {
          opacity: 0.8;
        }
        .icon {
          position: absolute;
          top: -8px;
          right: -8px;
          background: @bgColor;
          color: @disabled;
        }
      }
    }
  }

  .right-footer {
    padding: 12px 16px;
    border-top: 1px solid @primary-light1;
    background: @primary-light2;
  }
}
</style>
