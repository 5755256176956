<!--
  @name: 剧场首页
  @author: heyan
  @date:2021-10-09
-->

<template>
  <!--三级路由-->
  <router-view />
</template>

<script>
export default {
  name: "theater-index",
  data() {
    return {};
  },
  created() {},

  methods: {}
};
</script>

<style lang="less" scoped></style>
