<!--
  @name: 选择座位弹窗-升级版
  @date: 2022-07-01
  @author: 易远胜
  @prop: {
    visible: 是否展示弹窗
  }
  @event: sure // 确定选座，返回购物车需要的数据
                  [{theaterTicketId: "1", theaterTicketSpuId: "2",  seatIds: [3...]}]
  @method: backData /**
                     *@name: 重现选座回显方法
                     *@description: 父组件通过$refs调用该方法，传入的数组（arr）与选座返回的数组格式一致
                     *              调用该方法后会自动打开弹窗
                     *@date: 2020-10-29
                     *@author: 易远胜
                     *@params: {Array} arr 必传 回显的数据，与选座确定返回的数据格式一致
                     *@params: {String} spuId 必传,场次spuId,更新id
                
                     *@return: 无
                     */
 
-->
<template>
  <a-modal
    :title="placeName"
    centered
    :mask-closable="false"
    :visible="visible"
    @cancel="close"
    width="1000px"
    :body-style="{
      'max-height': maxHeight,
      overflow: 'auto'
    }"
  >
    <!--标题-->
    <p class="font-xl dark">{{ theaterName }}</p>
    <!--需要选座-->
    <template v-if="whetherChooseSeat">
      <div class="mb-2x">
        <div class="flex-start align-center flex-wrap gray">
          <p class="pr-4x mr-1x">今天5月1日 19:00 国语3D</p>
          <div>
            <i class="iconfont iconkuan font-xl text-v"></i>
            <span class="text-v ml-x">普通票档</span>
          </div>
        </div>
      </div>
      <!--左边座位-->
      <seat-list-new
        :horizontal-nums="horizontalNums"
        :vertical-nums="verticalNums"
        :seat-list="seatList"
        @change="seatChange"
      ></seat-list-new>

      <!--已选座位-->
      <template v-if="checkedList.length">
        <p class="checked-title">已选座位</p>
        <ul class="checked-list flex-start flex-wrap">
          <li
            class="checked-item flex-around flex-col"
            v-for="item in checkedList"
            :key="item.id"
          >
            <p class="text-1">{{ item.productName }}</p>
            <p class="price">{{ item.salePrice | money }}</p>
          </li>
        </ul>
      </template>
    </template>
    <!--不需要选座-->
    <div v-else class="gray">
      <p class="pr-4x mr-1x">今天5月1日 19:00 国语3D</p>
      <ul class="flex-start flex-wrap mt-1x">
        <li
          v-for="item in 5"
          :key="item"
          class="flex-start align-center level-item pt-2x"
        >
          <i class="iconfont iconkuan icon-level"></i>
          <span class="font-l mh-1x">普通座位</span>

          <i class="iconfont iconjianshao minu"></i>
          <input type="tel" class="input" value="5" />
          <i class="iconfont icontianjia1 plus"></i>
        </li>
      </ul>
    </div>

    <footer class="flex-center" slot="footer">
      <a-button shape="round" size="large" class="btn mr-2x" @click="close"
        >取消</a-button
      >
      <a-button
        type="primary"
        size="large"
        shape="round"
        class="btn"
        @click="sure"
        >确认选座</a-button
      >
    </footer>
  </a-modal>
</template>

<script>
import SeatListNew from "./SeatListNew";
import { placeSeatList } from "../../../../api/product";

export default {
  name: "SelectSeatNew",
  components: { SeatListNew },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 重新选座回显对象
    backSeat: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      whetherChooseSeat: true, // 是否要选座
      horizontalNums: [],
      verticalNums: [],
      seatList: [],
      checkedSets: [], // 选中座位
      ticketLevels: [], // 票档
      maxHeight: "85vh",
      placeName: "", // 场地名称
      merchantName: "", // 所属商户名称
      ticketLevelList: [], // 票档列表
      theaterName: "", // 场地名称
      checkedList: [], // 已选座位
      productId: "" // 产品id
    };
  },
  created() {
    let height = window.innerHeight;
    if (height <= 900) this.maxHeight = "80vh";
  },

  methods: {
    // 加载座位列表
    loadData(item) {
      this.theaterName = item.theaterName;
      this.productId = item.spuId;
      placeSeatList({
        placeTicketId: item.spuId,
        placeId: item.placeId,
        whetherChooseSeat: true
      })
        .then(
          ({
            placeName,
            merchantName,
            horizontalNums,
            verticalNums,
            ticketLevelList,
            placeTicketSeatList
          }) => {
            this.placeName = placeName;
            this.merchantName = merchantName;
            this.horizontalNums = horizontalNums;
            this.verticalNums = verticalNums;
            this.ticketLevelList = ticketLevelList;
            this.seatList = placeTicketSeatList.map(item => {
              return item.map(temp => {
                temp.checked = temp.checked = false;
                temp.salePrice =
                  ticketLevelList.find(
                    item => item.id === temp.productCoordinateGroupId
                  )?.price || 23;
                return temp;
              });
            });
            this.$emit("update:visible", true);
          }
        )
        .catch(() => {
          this.$message.error("处理座位信息异常");
        });
    },
    // 选择座位
    seatChange(arr) {
      this.checkedList = arr;
    },
    // 确定选座
    sure() {
      this.$emit("sure", {
        seatIds: this.checkedList.map(item => item.id)
      });
    },
    // 关闭
    close() {
      this.$emit("update:visible", false);
    },
    /**
     *@name: 重现选座回显方法
     *@description: 父组件通过$refs调用该方法，传入的数组（arr）与选座返回的数组格式一致
     *              调用该方法后会自动打开弹窗
     *@date: 2020-10-29
     *@author: 易远胜
     *@params: {Array} arr 必传 回显的数据，与选座确定返回的数据格式一致
     *@params: {String} spuId 必传,场次spuId,更新id

     *@return: 无
     */
    backData(arr, spuId) {
      this.id = spuId;
      this.seatList = [];
      this.loadDetail()
        .then(() => {
          this.$emit("update:visible", true);
          this.checkedSets = [];
          arr.forEach(item => {
            item.seatIds.forEach(temp => {
              let finded = false;
              for (let val of this.seatList) {
                for (let obj of val) {
                  if (temp === obj.id) {
                    obj.theaterTicketId = item.theaterTicketId;
                    obj.theaterTicketSpuId = item.theaterTicketSpuId;
                    obj.selected = true;
                    this.checkedSets.push(obj);
                    finded = true;
                    break;
                  }
                }
                if (finded) break;
              }
            });
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../../assets/less/custom";
/deep/.ant-modal-header {
  text-align: center;
}

/*不需要选座*/
.level-item {
  padding-right: 40px;
  .icon-level {
    font-size: 22px;
  }
  /*加号*/
  .minu,
  .plus {
    font-size: 28px;
    cursor: pointer;
  }
  .plus {
    color: #25a2f2;
  }
  .input {
    width: 30px;
    font-size: 16px;
    border: none;
    text-align: center;
    color: #25a2f2;
    &:focus {
      outline-color: #d7ecf2;
    }
  }
}

.btn {
  width: 140px;
}
/*已选座位预览*/
.checked-title {
  padding: 14px 0 4px;
}
.checked-list {
  .checked-item {
    width: 74px;
    height: 40px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 0 8px;
    margin: 10px 10px 0 0;
    font-size: 12px;
    > p {
      width: 100%;
    }
  }
}
</style>
