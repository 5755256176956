<template>
  <div class="tourist ps-r">
    <a-form-model layout="inline" :model="item" ref="formData">
      <!--游客-->
      <a-row>
        <i v-show="!item.levelOne" class="dashed-v-1"></i>
        <a-col :span="24" class="flex-start align-center">
          <div @click="spreadClick(item, 'levelOne')">
            <i v-show="!item.levelOne" class="iconfont iconshouqi icon-add"></i>
            <i
              v-show="item.levelOne"
              class="iconfont iconjiatianjiakuangxuanduoxuan-8 icon-add"
            ></i>
          </div>
          <span>游客</span>
        </a-col>
        <a-col v-show="!item.levelOne" :span="10" :offset="2" class="pl-1x">
          <a-form-model-item
            prop="visitorTypeId"
            :rules="{
              required: false,
              message: '请选择',
              trigger: 'change'
            }"
          >
            <a-select
              stle="width: 120px;"
              v-if="item.memberTouristList.length"
              placeholder="请选择"
              v-model="item.visitorTypeId"
              @change="changePlayer(item, 'visitorTypeId')"
            >
              <a-select-option
                :value="temp.id"
                v-for="temp in item.memberTouristList"
                :key="temp.id"
                >{{ temp.touristCategoryName }}</a-select-option
              >
            </a-select>
            <a-input v-else disabled placeholder="普通类型" />
          </a-form-model-item>
          <div class="red font-s">组团打8折</div>
        </a-col>
      </a-row>
      <!--游客信息-->
      <a-row
        class="over-hidden"
        v-if="
          item.visitorFieldList.length ||
            item.IdTypeList.length ||
            item.requiredPersonId ||
            item.fast === 1 ||
            item.requiredVoucherNo ||
            item.whetherRecommend
        "
      >
        <a-col v-show="!item.levelOne" :span="23" :offset="1" class="mt-x">
          <div class="flex-start align-center">
            <i class="icon-dashed dashed-1"></i>
            <i
              @click="spreadClick(item, 'levelTwo')"
              v-show="!item.levelTwo"
              class="iconfont iconshouqi"
            ></i>
            <i
              @click="spreadClick(item, 'levelTwo')"
              v-show="item.levelTwo"
              class="iconfont iconjiatianjiakuangxuanduoxuan-8"
            ></i>
            <span class="pl-1x">游客信息</span>
          </div>

          <a-row class="over-hidden ml-1x" v-show="!item.levelTwo">
            <i class="dashed-v-2"></i>
            <a-col :span="23" :offset="1" class="mt-x">
              <a-row :gutter="[6, 0]">
                <!--非证件类型字段-->
                <a-col
                  :xxl="12"
                  :xl="24"
                  :lg="24"
                  v-for="(temp, idx) in item.visitorFieldList"
                  :key="'v' + idx"
                  class="flex-start"
                >
                  <template
                    v-if="[1, 4, 10, 11, 12, 13, 14].includes(temp.fieldType)"
                  >
                    <p class="mr-1x label flex-shrink">
                      <span :class="temp.required ? 'red' : 'opacity'">*</span>
                      {{ temp.fieldName }}
                    </p>
                    <a-form-model-item
                      :prop="`visitorFieldList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请输入内容',
                          trigger: 'blur'
                        },
                        {
                          validator: checkText
                        }
                      ]"
                    >
                      <a-input
                        v-inputfocus
                        v-model="temp.fieldValue"
                        type="tel"
                        placeholder="请输入内容"
                        allow-clear
                        @blur="
                          changePlayer(
                            item,
                            `visitorFieldList.${idx}.fieldValue`
                          )
                        "
                      >
                      </a-input>
                    </a-form-model-item>
                  </template>
                  <template v-else-if="temp.fieldType === 3">
                    <p class="mr-1x label flex-shrink">
                      <span :class="temp.required ? 'red' : 'opacity'">*</span>
                      {{ temp.fieldName }}
                    </p>
                    <a-form-model-item
                      style="width: 128px;"
                      :prop="`visitorFieldList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: `请选择${temp.fieldName}`,
                          trigger: 'change'
                        }
                      ]"
                    >
                      <a-select
                        style="width: 120px"
                        v-model="temp.fieldValue"
                        placeholder="请选择"
                        @change="
                          changePlayer(
                            item,
                            `visitorFieldList.${idx}.fieldValue`
                          )
                        "
                      >
                        <a-select-option
                          :value="val"
                          v-for="(val, i) in temp.fieldOptions"
                          :key="'o' + i"
                        >
                          {{ val }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </template>
                  <template v-else-if="temp.fieldType === 5">
                    <p class="mr-1x label flex-shrink">
                      <span :class="temp.required ? 'red' : 'opacity'">*</span>
                      {{ temp.fieldName }}
                    </p>
                    <a-form-model-item
                      :prop="`visitorFieldList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请输入手机号',
                          trigger: 'blur'
                        },
                        {
                          validator: checkPhone
                        }
                      ]"
                    >
                      <a-input
                        v-inputfocus
                        v-model="temp.fieldValue"
                        type="tel"
                        placeholder="请输入内容"
                        allow-clear
                        @blur="
                          changePlayer(
                            item,
                            `visitorFieldList.${idx}.fieldValue`
                          )
                        "
                      >
                      </a-input>
                    </a-form-model-item>
                  </template>
                  <template v-if="[2, 7].includes(temp.fieldType)">
                    <p class="mr-1x label flex-shrink">
                      <span :class="temp.required ? 'red' : 'opacity'">*</span>
                      {{ temp.fieldName }}
                    </p>
                    <a-form-model-item
                      :prop="`visitorFieldList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请选择日期',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <a-date-picker
                        style="width: 100%;"
                        type="date"
                        valueFormat="YYYY-MM-DD"
                        @change="
                          changePlayer(
                            item,
                            `visitorFieldList.${idx}.fieldValue`
                          )
                        "
                        v-model="temp.fieldValue"
                      />
                    </a-form-model-item>
                  </template>
                  <template v-else-if="temp.fieldType === 8">
                    <p class="mr-1x label ">
                      <span :class="temp.required ? 'red' : 'opacity'">*</span>
                      {{ temp.fieldName }}
                    </p>
                    <a-form-model-item
                      style="width: 100%;"
                      :prop="`visitorFieldList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请选择性别',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <a-select
                        style="width: 100%;"
                        placeholder="请选择"
                        v-model="temp.fieldValue"
                        @change="
                          changePlayer(
                            item,
                            `visitorFieldList.${idx}.fieldValue`
                          )
                        "
                      >
                        <a-select-option
                          :value="val"
                          v-for="(val, i) in temp.fieldOptions"
                          :key="'s' + i"
                        >
                          {{ val }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </template>
                  <template v-else-if="temp.fieldType === 9">
                    <p class="mr-1x label flex-shrink">
                      <span :class="temp.required ? 'red' : 'opacity'">*</span>
                      {{ temp.fieldName }}
                    </p>
                    <a-form-model-item
                      style="width: 100px;"
                      :prop="`visitorFieldList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请上传证件照',
                          trigger: 'change'
                        }
                      ]"
                    >
                      <picture-input
                        class="m-x"
                        v-model="temp.fieldValue"
                        :small="true"
                        @input="
                          changePlayer(
                            item,
                            `visitorFieldList.${idx}.fieldValue`
                          )
                        "
                      ></picture-input>
                    </a-form-model-item>
                  </template>
                  <template v-else-if="temp.fieldType === 16">
                    <p class="mr-1x label">
                      <span :class="temp.required ? 'red' : 'opacity'">*</span>
                      {{ temp.fieldName }}
                    </p>
                    <a-form-model-item
                      style="width: 100%;"
                      :prop="`visitorFieldList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: `请输入${temp.fieldName}`,
                          trigger: 'change'
                        }
                      ]"
                    >
                      <a-select
                        style="min-width: 100px;width: 100%;"
                        placeholder="请选择国家"
                        v-model="temp.fieldValue"
                        @change="
                          changePlayer(
                            item,
                            `visitorFieldList.${idx}.fieldValue`
                          )
                        "
                      >
                        <a-select-option
                          :value="val"
                          v-for="(val, i) in temp.fieldOptions"
                          :key="'c' + i"
                        >
                          {{ val }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </template>
                </a-col>
                <!--人脸-->
                <a-col
                  v-if="item.requiredPersonId"
                  :xxl="12"
                  :xl="24"
                  :lg="24"
                  class="flex-start"
                >
                  <p class="mr-1x flex-shrink label">
                    <span class="red" v-if="item.requiredPersonTrue">*</span>
                    人脸
                  </p>
                  <a-form-model-item
                    prop="personId"
                    :rules="[
                      {
                        required: item.requiredPersonTrue === 1 ? true : false,
                        message: '请录入人脸'
                      }
                    ]"
                  >
                    <face-input
                      :small="true"
                      v-model="item.personId"
                      :key="Math.random()"
                      @imgUrlChange="res => faceChange(item, res)"
                      :echoimgurl="item.personFaceUrl"
                    ></face-input>
                  </a-form-model-item>
                </a-col>
                <!--出游人信息证件类型归类-->
                <a-col
                  :lg="24"
                  class="flex-start"
                  v-if="item.IdTypeList.length"
                >
                  <!--出游人信息证件类型归类-->
                  <a-select
                    v-model="item.IdType"
                    style="width:100px;margin-top: 4px;"
                  >
                    <a-select-option
                      :value="val.fieldType"
                      v-for="val in item.IdTypeList"
                      :key="val.fieldType"
                      >{{ val.fieldName }}</a-select-option
                    >
                  </a-select>

                  <section
                    v-for="(temp, idx) in item.IdTypeList"
                    :key="'i' + idx"
                  >
                    <!--身份证-->
                    <a-form-model-item
                      v-if="temp.fieldType === 6 && item.IdType === 6"
                      :prop="`IdTypeList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请输入身份证号',
                          trigger: 'blur'
                        },
                        {
                          validator: checkIdCard
                        }
                      ]"
                    >
                      <IdCardInput
                        :is-cart-verify="true"
                        :current-card-id.sync="currentCardId"
                        :ref="'idCardInput' + index"
                        v-inputfocus
                        v-model="temp.fieldValue"
                        @focus="idCardFocus"
                        @readCard="
                          readCard(
                            $event,
                            item,
                            `IdTypeList.${idx}.fieldValue`,
                            temp
                          )
                        "
                        @blur="
                          changePlayer(
                            isSubProduct ? wrapItem : item,
                            `IdTypeList.${idx}.fieldValue`,
                            temp
                          )
                        "
                      ></IdCardInput>
                    </a-form-model-item>
                    <!--社保卡-->
                    <a-form-model-item
                      v-if="temp.fieldType === 21 && item.IdType === 21"
                      :prop="`IdTypeList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请输入身份证号',
                          trigger: 'blur'
                        },
                        {
                          validator: checkIdCard
                        }
                      ]"
                    >
                      <IdCardInput
                        v-inputfocus
                        v-model="temp.fieldValue"
                        @blur="
                          changePlayer(
                            isSubProduct ? wrapItem : item,
                            `IdTypeList.${idx}.fieldValue`,
                            temp
                          )
                        "
                      ></IdCardInput>
                    </a-form-model-item>
                    <!--台胞证-->
                    <a-form-model-item
                      v-else-if="temp.fieldType === 17 && item.IdType === 17"
                      :prop="`IdTypeList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请输入证件号',
                          trigger: 'blur'
                        },
                        {
                          validator: checkText
                        }
                      ]"
                    >
                      <a-input
                        v-inputfocus
                        v-model="temp.fieldValue"
                        class="ib"
                        placeholder="请输入证件号"
                        allow-clear
                        @blur="
                          changePlayer(item, `IdTypeList.${idx}.fieldValue`)
                        "
                      >
                      </a-input>
                    </a-form-model-item>
                    <!--港澳通行证-->
                    <a-form-model-item
                      style="width:250px"
                      v-else-if="temp.fieldType === 18 && item.IdType === 18"
                      :prop="`IdTypeList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请输入证件号',
                          trigger: 'blur'
                        },
                        {
                          validator: checkText
                        }
                      ]"
                    >
                      <a-input
                        v-inputfocus
                        v-model="temp.fieldValue"
                        placeholder="请输入证件号"
                        allow-clear
                        @blur="
                          changePlayer(item, `IdTypeList.${idx}.fieldValue`)
                        "
                      >
                      </a-input>
                    </a-form-model-item>
                    <!--护照-->
                    <a-form-model-item
                      v-else-if="temp.fieldType === 19 && item.IdType === 19"
                      :prop="`IdTypeList.${idx}.fieldValue`"
                      :rules="[
                        {
                          required: temp.required,
                          message: '请输入证件号',
                          trigger: 'blur'
                        },
                        {
                          validator: checkText
                        }
                      ]"
                    >
                      <a-input
                        v-inputfocus
                        v-model="temp.fieldValue"
                        placeholder="请输入证件号"
                        allow-clear
                        @blur="
                          changePlayer(item, `IdTypeList.${idx}.fieldValue`)
                        "
                      >
                      </a-input>
                    </a-form-model-item>
                  </section>
                </a-col>
              </a-row>
              <!--卡腕带-->
              <a-row v-if="item.requiredVoucherNo">
                <a-col :span="8" class="label">
                  <i class="icon-dashed dashed-1"></i>
                  <span>卡腕带押金：</span>
                  <span class="price">{{ item.wristbandPrice | money }}</span>
                </a-col>
                <a-col :span="16" class="flex-start">
                  <p class="mr-1x flex-shrink label">
                    <span class="red">*</span>卡腕带
                  </p>
                  <a-form-model-item
                    prop="voucherNo"
                    :rules="[
                      {
                        required: true,
                        message: '请输入卡腕带号',
                        trigger: 'blur'
                      },
                      {
                        validator: checkText
                      }
                    ]"
                  >
                    <IcCardInput
                      v-inputfocus
                      v-model="item.voucherNo"
                      :allow-clear="false"
                      placeholder="请输入卡/腕带编号"
                      @blur="changePlayer(item, 'voucherNo')"
                    ></IcCardInput>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <!--加购产品-->
              <template v-if="item.whetherRecommend">
                <div class="flex-between align-center mt-1x">
                  <div class="ps-r">
                    <i class="icon-dashed dashed-1"></i>
                    <i
                      @click="spreadClick(item, 'levelThree')"
                      v-show="!item.levelThree"
                      class="iconfont iconshouqi"
                    ></i>
                    <i
                      @click="spreadClick(item, 'levelThree')"
                      v-show="item.levelThree"
                      class="iconfont iconjiatianjiakuangxuanduoxuan-8"
                    ></i>
                    <span class="pl-1x">加购产品</span>
                  </div>
                  <span class="primary pointer" @click="selectAdd(item)"
                    >选择</span
                  >
                </div>
                <a-row
                  v-if="item.subProductList.length && !item.levelThree"
                  class="over-hidden ml-1x"
                >
                  <i class="dashed-v-3"></i>
                  <a-col :span="22" :offset="2">
                    <ul class="align-start mt-1x">
                      <li
                        style="min-height: 27px;"
                        class="flex-between align-center ps-r"
                        v-for="(temp, idx) in item.subProductList"
                        :key="temp.productId"
                        :class="{ 'mt-2x': idx > 0 }"
                      >
                        <i class="icon-dashed dashed-2"></i>
                        <div class="flex-start">
                          <a-checkbox
                            class=" mr-x"
                            :checked="!!temp.productFlagPO.whetherCheck"
                            @change="checkAddBuy(item, temp)"
                          >
                            <!--@click="checkAddBuy(item, temp, obj)"-->
                          </a-checkbox>
                          <div
                            class="flex-start align-center"
                            style="max-width: 250px;"
                          >
                            <p class="text-1">{{ temp.productName }}</p>
                            <p class="flex-shrink price ml-1x">
                              {{ temp.sellingAmount | money }}
                            </p>
                          </div>
                        </div>
                        <div class="flex-start">
                          <div
                            class="flex-start align-center mr-1x"
                            v-if="temp.productFlagPO.whetheChangeNum"
                          >
                            <a-icon
                              type="minus-circle"
                              class="minus"
                              @click="minusAddBuy(item, temp)"
                            />
                            <input
                              v-inputfocus
                              :value="temp.productFlagPO.buyNum"
                              class="input-number"
                              onkeyup="this.value=this.value.replace(/\D/g,'')"
                              @blur="e => addInputBlur(e, temp, item)"
                            />
                            <a-icon
                              type="plus-circle"
                              theme="filled"
                              class="plus"
                              @click="plusAddBuy(item, temp)"
                            />
                          </div>
                          <p v-else>x {{ temp.productFlagPO.buyNum }}</p>
                          <div
                            v-if="temp.productFlagPO.whetheChangeNum"
                            class="pointer flex-shrink"
                            @click="delAddBuy(item, temp)"
                          >
                            <b class="iconfont icondelete ml-x font-xl red"></b>
                            <!--<span>删除</span>-->
                          </div>
                        </div>
                      </li>
                    </ul>
                  </a-col>
                </a-row>
              </template>
              <!--快速通行服务-->
              <div class="pv-1x flex-between" v-if="item.fast === 1">
                <div class="ps-r">
                  <i class="icon-dashed dashed-1"></i>
                  <b class="label">快速通行服务：</b>
                  <b class="price ml-x mr-4x">{{
                    item.fastChannelFee | money
                  }}</b>
                </div>
                <a-switch
                  color="#25a2f2"
                  v-model="item.whetherFastTicket"
                  @change="fastChange(item)"
                />
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-form-model>
    <a-button @click="validate">校验</a-button>
  </div>
</template>

<script>
import {
  checkPhone,
  checkIdCard,
  checkText,
  checkEmail
} from "../../../../utils/global";
import PictureInput from "@/components/hardware/PictureInput.vue";
import IdCardInput from "@/components/hardware/IdCardInput";
import IcCardInput from "@/components/hardware/IcCardinput";
import FaceInput from "@/components/hardware/FaceInput.vue";
export default {
  name: "TouristInfo",
  components: {
    PictureInput,
    IdCardInput,
    IcCardInput,
    FaceInput
  },
  props: {
    // 子产品时的主产品
    wrapItem: {
      type: Object,
      default() {
        return {};
      }
    },
    // 是否子产品
    isSubProduct: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    index: {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  data() {
    return {
      checkPhone,
      checkIdCard,
      checkText,
      checkEmail,
      isReadCard: false, // 是否是读取身份证
      currentCardId: "" // 当前刷的身份证号
    };
  },
  methods: {
    // 人脸、证件识别涉及的方法区域 （如果是组合产品：一子产品一游客 val为obj）
    readCard(res, val, key, temp, valItem) {
      if (this.isSubProduct) {
        valItem = this.wrapItem;
      }
      this.$emit("readCard", res, val, key, temp, valItem);
    },
    // 修改游客信息
    /**
     * @params item Object  主产品行
     * @params key String  校验的key
     * @params val Object  动态表单行
     * @params temp Object  子产品行
     * */
    changePlayer(item, key, val = {}, temp) {
      if (this.isSubProduct) {
        temp = item;
        item = this.wrapItem;
      }
      this.$refs.formData.validateField(key, valid => {
        if (!valid) {
          this.$emit("changePlayer", item, val, temp);
        }
      });
    },
    idCardFocus(val) {
      this.$emit("idCardFocus", val);
    },
    // 人脸录入
    faceChange(item, res, obj) {
      if (this.isSubProduct) {
        obj = item;
        item = this.wrapItem;
      }
      if (obj) obj.personFaceUrl = res;
      else item.personFaceUrl = res;
      this.$emit("changePlayer", item, obj);
    },
    // 快速通行
    fastChange(item, obj) {
      if (this.isSubProduct) {
        obj = item;
        item = this.wrapItem;
      }
      this.$emit("fastChange", item, obj);
    },
    // 选择加购 mainItem: 如果是组合产品一子产品一游客则存在mainItem
    selectAdd(row, mainItem = {}) {
      if (this.isSubProduct) {
        mainItem = this.wrapItem;
      }
      this.$emit("selectAdd", row, mainItem);
    },
    // 选中加购
    checkAddBuy(item, temp, obj) {
      if (this.isSubProduct) {
        obj = item;
        item = this.wrapItem;
      }
      this.$emit("checkAddBuy", item, temp, obj);
    },
    // 加购加
    plusAddBuy(item, temp, obj) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      if (this.isSubProduct) {
        obj = item;
        item = this.wrapItem;
      }
      this.$emit("minusAddBuy", item, temp, obj);
    },
    // 加购减
    minusAddBuy(item, temp, obj) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      if (this.isSubProduct) {
        obj = item;
        item = this.wrapItem;
      }
      this.$emit("minusAddBuy", item, temp, obj);
    },
    // 删除加购
    delAddBuy(item, temp, obj) {
      if (this.isSubProduct) {
        obj = item;
        item = this.wrapItem;
      }
      this.$emit("delAddBuy", item, temp, obj);
    },
    // 更新加购产品
    addInputBlur(e, item, temp, obj) {
      if (this.isSubProduct) {
        obj = item;
        item = this.wrapItem;
      }
      this.$emit("addInputBlur", e, item, temp, obj);
    },
    // 校验购物车表单
    validate() {
      let ok = true;
      // 如果购物车需要填游客信息则需要校验游客信息合法性
      if (this.$refs.formData) {
        this.$refs.formData.validate(valid => {
          if (!valid) {
            this.$message.warning("请正确填写购物信息");
            ok = false;
          }
        });
      }
      return ok;
    },
    // 展开
    spreadClick(item, key) {
      this.$set(item, key, !item[key]);
      this.$emit("spreadClick");
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../../assets/less/custom.less";
.tourist {
  /deep/.ant-form-inline .ant-form-item {
    width: 100%;
    margin-right: 0 !important;
  }
  /deep/.ant-form-item-control-wrapper {
    width: 100%;
  }
  /deep/.ant-input {
    padding: 4px 30px 4px 11px;
  }
  .label {
    position: relative;
    margin-top: 10px;
    min-width: 60px;
  }
  .opacity {
    opacity: 0;
  }
  /deep/.ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0 !important;
  }
  /*加减号*/
  .minus {
    color: #a4a4a4;
    font-size: 20px;
    cursor: pointer;
  }
  .number {
    margin: 0 16px;
    line-height: 24px;
    font-weight: 500;
    font-size: 18px;
    color: #25a2f2;
  }
  /*减号*/
  .plus {
    font-size: 20px;
    color: #25a2f2;
    cursor: pointer;
  }
  .disabled {
    cursor: not-allowed;
  }
  .input-number {
    margin: 0 4px;
    border: none;
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 18px;
    outline-color: #dcdfe6;
    color: #25a2f2;
    background: #f1f1f1;
  }
  .ps-r {
    position: relative;
  }
  .ps-a {
    position: absolute;
  }
  .icon-add {
    position: relative;
    left: -7px;
  }
  /*游客虚线竖行*/
  .dashed-v-1 {
    position: absolute;
    height: 100%;
    top: 17px;
    left: 0;
    border-left: 1px dashed @border;
  }
  /*游客信息虚线竖行*/
  .dashed-v-2 {
    position: absolute;
    height: 100%;
    top: -16px;
    left: 0;
    border-left: 1px dashed @border;
  }
  /*加购虚线竖行*/
  .dashed-v-3 {
    position: absolute;
    height: 100%;
    top: -13px;
    left: 0;
    border-left: 1px dashed @border;
  }
  /*虚线横向*/
  .icon-dashed {
    height: 1px;
    border-top: 1px dashed @border;
    position: absolute;
    top: 12px;
    /*游客信息，快速通行服务*/
    &.dashed-1 {
      left: -26px;
      width: 25px;
    }
    /*加购*/
    &.dashed-2 {
      left: -60px;
      width: 55px;
    }
  }
  .over-hidden {
    overflow: hidden;
  }
}
</style>
